import { useEffect, useState } from 'react';
import Header from 'apps/broker/components/Header';
import SideMenu from 'apps/broker/components/Sidebar';
import Main from 'apps/broker/components/MainContent';
import { MdOutlineAddBusiness } from 'react-icons/md';
import Link from 'components/DS/Link';
import TableResult from './components/TableResult';
import { Checkbox } from '@mui/material';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import './styles.scss';

const Estipulantes = () => {
  const [inputNameFilter, setInputNameFilter] = useState('');
  const [healthProductFilter, setHealthProductFilter] = useState(true);
  const [dentalProductFilter, setDentalProductFilter] = useState(true);
  const [activeProducts, setActiveProducts] = useState(false);

  const searchInput = ({ target }) => {
    setInputNameFilter(target.value);
  };

  const handleFilterStipulatorsByContract = ({ target }) => {
    if (target.value === 'ativos') {
      setHealthProductFilter(true);
      setDentalProductFilter(true);
      setActiveProducts(true);
    }

    if (target.value === 'todos') {
      setHealthProductFilter(target.checked);
      setDentalProductFilter(target.checked);
      setActiveProducts(false);
    }

    if (target.value === 'saude') {
      setHealthProductFilter(target.checked);
    }

    if (target.value === 'odonto') {
      setDentalProductFilter(target.checked);
    }
  };

  useEffect(() => {
    if (!healthProductFilter && !dentalProductFilter) {
      setActiveProducts(false);
    }
  }, [healthProductFilter, dentalProductFilter]);

  return (
    <div className="policy-owners">
      <SideMenu />
      <div className="policy-owners__content">
        <Header />
        <Main>
          <div className="policy-owners__content--main-painel">
            <section className="policy-owners__consult">
              <div className="policy-owners__consult--presentation-container">
                <div className="policy-owners__consult--title">
                  <h1>Consulta de Estipulantes</h1>
                </div>
                <div className="policy-owners__consult--search">
                  <label>Consultar por nome</label>
                  <input
                    type="text"
                    placeholder="Pesquisar Estipulantes.."
                    onChange={searchInput}
                  />
                </div>
              </div>
              <div className='policy-owners__consult--filter-container'>
                <label className='policy-owners__consult--filter-container-label'>Consultar por produto</label>
                <div className='policy-owners__consult--filter'>
                  <Checkbox
                    id='todos'
                    size="small"
                    value={'todos'}
                    icon={<IoMdRadioButtonOff size={16} />}
                    checkedIcon={<IoMdRadioButtonOn size={16} />}
                    IoMdRadioButtonOffIcon={<IoMdRadioButtonOff size={16} />}
                    checked={healthProductFilter && dentalProductFilter && !activeProducts}
                    onChange={handleFilterStipulatorsByContract}
                  />
                  <label htmlFor="todos">Todos</label>
                  <Checkbox
                    id='saude'
                    size="small"
                    value={'saude'}
                    icon={<IoMdRadioButtonOff size={16} />}
                    checkedIcon={<IoMdRadioButtonOn size={16} />}
                    IoMdRadioButtonOffIcon={<IoMdRadioButtonOff size={16} />}
                    checked={healthProductFilter}
                    onChange={handleFilterStipulatorsByContract}
                  />
                  <label htmlFor="saude">Saúde</label>
                  <Checkbox
                    id='odonto'
                    size="small"
                    value={'odonto'}
                    icon={<IoMdRadioButtonOff size={16} />}
                    checkedIcon={<IoMdRadioButtonOn size={16} />}
                    IoMdRadioButtonOffIcon={<IoMdRadioButtonOff size={16} />}
                    checked={dentalProductFilter}
                    onChange={handleFilterStipulatorsByContract}
                  />
                  <label htmlFor="odonto">Odonto</label>
                  <Checkbox
                    id='ativos'
                    size="small"
                    value={'ativos'}
                    icon={<IoMdRadioButtonOff size={16} />}
                    checkedIcon={<IoMdRadioButtonOn size={16} />}
                    IoMdRadioButtonOffIcon={<IoMdRadioButtonOff size={16} />}
                    checked={activeProducts}
                    onChange={handleFilterStipulatorsByContract}
                  />
                  <label htmlFor="ativos">Ativos</label>
                </div>
              </div>
              <div className="policy-owners__consult--link-container">
                <Link to="/cadastro-estipulante">
                  <MdOutlineAddBusiness size={21} />
                  <span>Adicionar Estipulante</span>
                </Link>
              </div>
            </section>
            <TableResult
              inputNameFilter={inputNameFilter}
              healthProductFilter={healthProductFilter}
              dentalProductFilter={dentalProductFilter}
              activeProducts={activeProducts}
            />
          </div>
        </Main>
      </div>
    </div>
  );
};

export default Estipulantes;
