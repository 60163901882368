import { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { registerLocale } from 'react-datepicker';
import ptBR from 'date-fns/locale/pt-BR';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Backdrop from '@material-ui/core/Backdrop';
import Excel from 'exceljs';
import { saveAs } from 'file-saver';
import { BiFolderOpen } from 'react-icons/bi';
import { RiFileWarningLine } from 'react-icons/ri';
import CloseIcon from '@material-ui/icons/Close';
import services from 'apps/broker/services';
import { Wrapper } from './styles-VidasInner';
import TopBar from 'apps/broker/components/HorizontalBar/topBar';
import BottomBar from 'apps/broker/components/HorizontalBar/bottomBar';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
    '& > * + *': { marginLeft: theme.spacing(2) }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: '#fff'
  },
  load: { color: '#6e3ec1' }
}));

registerLocale('ptBR', ptBR);

const VidasInner = ({ idref }) => {
  // console.log(idref)
  const classes = useStyles();
  const [loadApi, setLoadApi] = useState(false);
  const { register, handleSubmit, watch, setValue } = useForm();

  const [planos, setPlanos] = useState([]);
  const [produto, setProduto] = useState();
  const [estipulantes, setEstipulantes] = useState([]);
  const [resp, setResp] = useState(false);
  const [respErrors, setRespErrors] = useState(false);
  const [respData, setRespData] = useState('');
  const [respBeneficiarios, setRespBeneficiarios] = useState([]);

  const getValues = () => {
    services.insuranceCompanies
      .getPlansByContractIdref(idref)
      .then((resp) => {
        setEstipulantes(resp.data.estipulantes);
        setPlanos(resp.data.planos);
        setProduto(resp.data.produto);
      })
      .catch((err) => console.log(err));
  };

  const onSubmit = (data) => {
    if (!data.arquivo) {
      return;
    }
    setLoadApi(true);
    const formData = new FormData();
    formData.append('arquivo', data.arquivo[0]);

    services.beneficiaries
      .createByContractIdref(idref, formData)
      .then((resp) => {
        // console.log(resp.data)
        if ('errors' in resp.data) {
          setRespErrors(resp.data.errors);
          setResp(false);
        } else {
          setResp(true);
          setRespErrors(false);
          setRespData(resp.data.message);
          setRespBeneficiarios(resp.data.beneficiarios);
        }
      })
      .finally(() => {
        setLoadApi(false);
        setTimeout(() => {
          setResp(false);
          setRespErrors(false);
          setRespData('');
          setRespBeneficiarios([]);
          setValue('arquivo', []);
        }, 1000);
      });
  };

  const typeColumns = {
    Saúde: [
      {
        key: 'cnpj',
        style: {
          alignment: { vertical: 'middle', horizontal: 'center', height: 25 }
        },
        width: 30
      },
      {
        key: 'nome',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'email',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'telefone',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'data_nascimento',
        style: {
          numFmt: '00"/"00"/"0000',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        height: 25,
        width: 30
      },
      {
        key: 'cpf',
        style: {
          numFmt: '000"."000"."000"-"00',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        height: 25,
        width: 30
      },
      {
        key: 'cpf_titular',
        style: {
          numFmt: '000"."000"."000"-"00',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        height: 25,
        width: 40
      },
      {
        key: 'sexo',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'grau_parentesco',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'tipo_contrato',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'data_contratacao',
        style: {
          numFmt: '00"/"00"/"0000',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        height: 25,
        width: 60
      },
      {
        key: 'plano_escolhido',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'carteirinha',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'data_entrada',
        style: {
          numFmt: '00"/"00"/"0000',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        height: 25,
        width: 50
      }
    ],
    Odonto: [
      {
        key: 'cnpj',
        style: {
          alignment: { vertical: 'middle', horizontal: 'center', height: 25 }
        },
        width: 30
      },
      {
        key: 'nome',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'email',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'telefone',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'data_nascimento',
        style: {
          numFmt: '00"/"00"/"0000',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        height: 25,
        width: 30
      },
      {
        key: 'cpf',
        style: {
          numFmt: '000"."000"."000"-"00',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        height: 25,
        width: 30
      },
      {
        key: 'cpf_titular',
        style: {
          numFmt: '000"."000"."000"-"00',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        height: 25,
        width: 40
      },
      {
        key: 'sexo',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'grau_parentesco',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'tipo_contrato',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'data_contratacao',
        style: {
          numFmt: '00"/"00"/"0000',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        height: 25,
        width: 60
      },
      {
        key: 'plano_escolhido',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'carteirinha',
        style: { alignment: { vertical: 'middle', horizontal: 'center' } },
        height: 25,
        width: 30
      },
      {
        key: 'data_entrada',
        style: {
          numFmt: '00"/"00"/"0000',
          alignment: { vertical: 'middle', horizontal: 'center' }
        },
        height: 25,
        width: 50
      }
    ]
  };

  const downloadExcel = (e) => {
    e.preventDefault();
    const workbook = new Excel.Workbook();

    const worksheet = workbook.addWorksheet('Planilha de Implantação', {
      properties: { tabColor: { argb: 'FF00FF00' } }
    });

    const worksheetPlanos = workbook.addWorksheet('Planos');
    worksheetPlanos.columns = [{ header: 'planos', key: 'planos' }];

    const headerRow = worksheet.getRow(1);
    headerRow.height = 30;

    if (produto.search === 'Saúde') {
      const arrayColumns = [
        'CNPJ do estipulante*',
        'Nome*',
        'Email(opcional)',
        'Telefone(opcional)',
        'Data de nascimento*',
        'CPF*',
        'Se dependente, CPF do titular*',
        'Sexo',
        'Grau parentesco',
        'Vinculo',
        'Data de Início do Vínculo com o Estipulante(opcional)',
        'Plano Saúde*',
        'Carteirinha*',
        'Data de entrada no plano(opcional)'
      ];
      worksheet.getRow(1).values = arrayColumns;
      worksheet.columns = typeColumns['Saúde'];
    } else if (produto.search === 'Odonto') {
      const arrayColumns = [
        'CNPJ do estipulante*',
        'Nome*',
        'Email(opcional)',
        'Telefone(opcional)',
        'Data de nascimento*',
        'CPF*',
        'Se dependente, CPF do titular*',
        'Sexo',
        'Grau parentesco',
        'Vinculo',
        'Data de Início do Vínculo com o Estipulante(opcional)',
        'Plano Dental*',
        'Carteirinha*',
        'Data de entrada no plano(opcional)'
      ];
      worksheet.getRow(1).values = arrayColumns;
      worksheet.columns = typeColumns['Odonto'];
    }

    let formStringEstipulantes = '';
    estipulantes.map((element, index) => {
      if (index === estipulantes.length - 1)
        return (formStringEstipulantes += `${element.cnpj}`);
      return (formStringEstipulantes += `${element.cnpj},`);
    });

    worksheet.dataValidations.add('A2:A9999', {
      type: 'list',
      allowBlank: true,
      formulae: [`"${formStringEstipulantes}"`],
      showErrorMessage: true,
      errorStyle: 'Erro',
      errorTitle: 'Selecione uma opção',
      error: 'Selecione uma das opções entre os Estipulantes'
    });

    worksheet.dataValidations.add('C2:C9999', {
      type: 'custom',
      allowBlank: true,
      formulae: ['=IF(C2="",TRUE,MATCH("*@*.?*",C2,0)>0)'],
      showErrorMessage: true,
      errorStyle: 'error',
      errorTitle: 'Email Inválido',
      error: 'Por favor, insira um endereço de email válido'
    });

    worksheet.dataValidations.add('E2:E9999', {
      type: 'date',
      allowBlank: true,
      showErrorMessage: true,
      errorStyle: 'Erro',
      errorTitle: 'Insira o dado no formato correto',
      error: 'O dado de entrada deve ser no formato "DD/MM/YYYY"',
      formulae: ['DATE(1900, 1, 1)', 'TODAY()']
    });

    worksheet.dataValidations.add('K2:K9999', {
      type: 'date',
      allowBlank: true,
      showErrorMessage: true,
      errorStyle: 'Erro',
      errorTitle: 'Insira o dado no formato correto',
      error: 'O dado de entrada deve ser no formato "DD/MM/YYYY"',
      formulae: ['DATE(1900, 1, 1)', 'TODAY()']
    });

    worksheet.dataValidations.add('N2:N9999', {
      type: 'date',
      allowBlank: true,
      showErrorMessage: true,
      errorStyle: 'Erro',
      errorTitle: 'Insira o dado no formato correto',
      error: 'O dado de entrada deve ser no formato "DD/MM/YYYY"',
      formulae: ['DATE(1900, 1, 1)', 'TODAY()']
    });

    worksheet.dataValidations.add('H2:H9999', {
      type: 'list',
      allowBlank: true,
      formulae: ['"Masculino, Feminino, Outros"'],
      showErrorMessage: true,
      errorStyle: 'Erro',
      errorTitle: 'Selecione uma opção',
      error: 'Selecione uma das opções'
    });

    worksheet.dataValidations.add('J2:J9999', {
      type: 'list',
      allowBlank: true,
      formulae: ['"CLT,PJ,Sócio,Estagiário"']
    });

    worksheet.dataValidations.add('I2:I9999', {
      type: 'list',
      allowBlank: true,
      formulae: [
        '"Filho(a),Cônjuge,Pai,Mãe,Irmão,Irmã,Tio(a),Cunhado(a),Sogro(a),Genro,Nora,Primo(a)"'
      ]
    });

    planos.map((element, index) => {
      worksheetPlanos.addRow({ planos: element.tipo });
    });

    let formStringPlanos = '';
    planos.map((element, index) => {
      if (index === planos.length - 1)
        return (formStringPlanos += `${element.tipo}`);
      return (formStringPlanos += `${element.tipo},`);
    });

    worksheet.dataValidations.add('L2:L9999', {
      textLength: 10000,
      type: 'list',
      allowBlank: true,
      // formulae: [`Planos!$A$2:$A$500`],
      formulae: [`Planos!$A$2:$A$500`],
      showErrorMessage: true,

      errorStyle: 'Erro',
      errorTitle: 'Selecione uma opção',
      error:
        planos.length > 1
          ? 'Selecione uma opção entre os Planos de Saúde'
          : 'Selecione o Plano de Saúde'
    });

    worksheet.eachRow({ includeEmpty: true }, function (Row, rowNum) {
      Row.eachCell({ includeEmpty: true }, function (Cell, colNumber) {
        if (rowNum === 1) {
          if (
            colNumber === 1 ||
            colNumber === 2 ||
            colNumber === 5 ||
            colNumber === 6 ||
            colNumber === 7 ||
            colNumber === 12 ||
            colNumber === 13
          ) {
            Cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'ff0000' }
            };
          } else {
            Cell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: '62A0E2' }
            };
          }
          Cell.font = {
            bold: true,
            size: 14,
            color: { argb: 'FFFFFF' }
          };
        }

        Cell.alignment = {
          vertical: 'middle',
          horizontal: 'center'
        };
      });
    });

    worksheet.properties.defaultRowHeight = 20;
    worksheet.properties.defaultColWidth = 30;

    saveFile('Planilha de Implantação', workbook);
  };

  async function saveFile(fileName, workbook) {
    const xls64 = await workbook.xlsx.writeBuffer({ base64: true });
    saveAs(
      new Blob([xls64], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
      }),
      fileName
    );
  }

  useEffect(() => {
    getValues();
  }, []);

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={loadApi}
        onClick={() => setLoadApi(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Wrapper>
        <div className="form-novas-vidas">
          <TopBar title={'Adicionar vidas'} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="buttons-container">
              <div>
                <button
                  className="btn-vidas hover-pointer"
                  onClick={(e) => downloadExcel(e)}
                >
                  Baixar
                </button>
              </div>
              <div className="input-file-group">
                <label className="label-file hover-pointer">
                  {watch('arquivo')?.[0]?.name ? (
                    <span>
                      <BiFolderOpen />{' '}
                      {`${watch('arquivo')[0]?.name.slice(0, 45)}`}{' '}
                    </span>
                  ) : (
                    <span>Inserir planilha </span>
                  )}
                  <input
                    {...register('arquivo', { required: false })}
                    className="input-file"
                    type="file"
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  />
                </label>

                {watch('arquivo')?.[0]?.name && (
                  <button
                    type="button"
                    onClick={() => {
                      setValue('arquivo', null);
                    }}
                    className="btn-clear-vidas"
                  >
                    <CloseIcon />
                  </button>
                )}
              </div>
              <button type="submit" className="label-file hover-pointer">
                Enviar Planilha
              </button>
            </div>
          </form>
          <div>
            {respErrors ? (
              <div className="data-resp-errors">
                <p className="main-error-message">
                  <RiFileWarningLine size={20} className="warning-icon" />
                  Por favor verificar os dados inseridos na tabela
                </p>
                <div className="data-error-columns">
                  {respErrors.map((errorObj) => {
                    const errorKeys = Object.keys(errorObj);

                    return (
                      <div>
                        <p className="line-error-message">
                          {errorKeys.length > 1
                            ? 'Erros encontrados na linha'
                            : 'Erro encontrado na linha'}{' '}
                          {Number(errorObj[`${errorKeys[0]}`].index) + 2}
                        </p>
                        {errorKeys.map((error, index) => (
                          <p className="error-column-message">- {error}</p>
                        ))}
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : (
              ''
            )}
            {resp ? (
              <div className="data-resp">
                {respBeneficiarios.length > 0
                  ? respData
                  : 'Planilha inserida estava vazia'}
                {respBeneficiarios.length <= 0 ? (
                  <></>
                ) : (
                  respBeneficiarios.forEach((element, index) => {
                    <div>{/* {console.log(element)} */}</div>;
                  })
                )}
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
        <BottomBar />
      </Wrapper>
    </>
  );
};

export default VidasInner;
