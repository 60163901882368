import styled from "styled-components";
import colors from "styles/colors";

export const DarkenBackground = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 101vh;
  background-color: rgba(0, 0, 0, 0.5);

  z-index: 1000;
`

export const PopperWrapper = styled.div`
  position: absolute;

  width: calc(410px - 40px);
  height: calc(250px - 30px);
  padding: 20px 15px;

  top: calc(50% - 125px);
  right: calc(350px - 205px);

  box-shadow: 0 25px 50px rgba(0,0,0,0.30), 0 22px 25px rgba(0,0,0,0.22);
  background-color: white;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

export const TitleContainer = styled.div`
  font-size: 14px;
  font-family: "Avenir Next Geo W05 Bold";
  color: ${colors.genoaDarkBlue};
  span {
    padding-left: 8px;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 9px;
  padding-top: 10px;
  
  .agendamento-row {
    display: flex;
    justify-content: space-between;
    border: 2px solid #cecece;
    border-radius: 20px;
    padding: 8px 6px;
  }

  .agendamento-span {
    font-size: 14px;
    color: ${colors.rhBlue};
    font-family: "Avenir Next Geo W05 Demi";
  }

  .input-data {
    width: 120px;
    height: 24px;
    border: 2px solid ${colors.rhBlue};
    display: inline-block;
    overflow: hidden;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    border-radius: 15px;
    // line-height: 1.2rem;
    font-size: 12px;
    padding: 6px;
  }

  .react-datepicker__day--selected {
    background-color: ${colors.rhBlue};
    font-weight: bold;
  }

  .react-datepicker__day--keyboard-selected {
    background-color: white;
    color: ${colors.genoaGrey};
    font-weight: bold;
  }

  .disclaimer-container {
    font-family: "Avenir Next GEO W05 Regular";
    font-size: 13px;
    color: ${colors.genoaPink};
    padding-left: 8px;
  }
`

export const ButtonContainer = styled.div`
  

  button {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    color: ${colors.genoaDarkBlue};
    font-family: "Avenir Next GEO W05 Demi";
    font-size: 16px;
    display: flex;
    align-items: center;
    column-gap: 7px;
    padding-left: 8px;
  }

  .disabled {
    color: ${colors.genoaDisabledGrey};

    .send-icon {
      color: ${colors.genoaDisabledGrey};
    }
  }

  .send-icon {
    font-size: 11px;
    color: ${colors.genoaDarkBlue};
    margin-top: 1px;
  }
`