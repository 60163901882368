import ReactDatePicker from 'react-datepicker';
import IncomingTable from './IncomingTable';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import './style.scss';

const IncomingInformation = ({
  policyOwnerList,
  policyOwnerId,
  setPolicyOwnerId,
  setIncomingContractStatus,
  incomingInitialDate,
  setIncomingInitialDate,
  incomingFinalDate,
  setIncomingFinalDate,
  incomingMessage,
  incoming
}) => {
  return (
    <div className="incoming-information">
      <div className="incoming-information__header">
        <h2 className="incoming-information__header--title">
          Receita por estipulante
        </h2>
      </div>
      <div className="incoming-information-filter">
        <div className="incoming-information-filter__content">
          <label className="incoming-information-filter__label">
            Filtrar por empresa:
          </label>
          <select
            className="incoming-information-filter__select"
            value={policyOwnerId}
            onChange={(e) => setPolicyOwnerId(e.target.value)}
          >
            <option value="" disabled>
              Selecione...
            </option>
            {policyOwnerList?.map((item) => (
              <option key={item.value} value={item.value}>
                {item.option}
              </option>
            ))}
          </select>
        </div>
        <div className="incoming-information-filter__content">
          <label className="incoming-information-filter__label">
            Filtrar por período:
          </label>
          <div className="incoming-information-filter__period">
            <div>
              <ReactDatePicker
                className="incoming-information-filter__input-date"
                selected={incomingInitialDate}
                locale="ptBR"
                onChange={(date) => {
                  setIncomingInitialDate(date);
                }}
                placeholderText="Data inicial"
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div>
              <ReactDatePicker
                className="incoming-information-filter__input-date"
                selected={incomingFinalDate}
                disabled={!incomingInitialDate}
                locale="ptBR"
                onChange={(date) => setIncomingFinalDate(date)}
                placeholderText="Data final"
                maxDate={new Date()}
                dateFormat="dd/MM/yyyy"
              />
            </div>
          </div>
        </div>
        <div className="incoming-information-filter__content">
          <label className="incoming-information-filter__label">Status:</label>
          <div className="incoming-information-filter__radio-group">
            <RadioGroup
              aria-labelledby="radio-filter"
              defaultValue="Ativo"
              name="radio-filter"
              onChange={(e) => setIncomingContractStatus(e.target.value)}
            >
              <FormControlLabel value="Todos" control={<Radio />} label="Todos" />
              <FormControlLabel
                value="Ativo"
                control={<Radio />}
                label="Ativos"
              />
              <FormControlLabel
                value="Inativo"
                control={<Radio />}
                label="Inativos"
              />
            </RadioGroup>
          </div>
        </div>
      </div>
      {incoming?.length > 0 ? (
        <IncomingTable incoming={incoming} />
      ) : (
        <span>{incomingMessage}</span>
      )}
    </div>
  );
};

export default IncomingInformation;
