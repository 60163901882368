import React, { useEffect } from 'react';
import Input from 'apps/lp/components/Form/Input';
import Select from 'apps/lp/components/Form/Select';
import Button from 'components/DS/Button';
import { useFormContext } from 'react-hook-form';
import nacionalidades from 'utils/arrays/nacionalidades';
import estadosCivis from 'utils/arrays/estadosCivis';
import banks from 'apps/lp/pages/Questionario/components/FormStages/banks.json';
import estadosBrasileiros from 'utils/arrays/estadosBrasileiros';
import { isLocalhost } from 'utils/browser';
import removeMask from 'utils/inputMasks/removeMask';
import service from 'apps/lp/services';
import './styles.scss';

const FormSecondStep = ({
  prevStep,
  nextStep,
  configFields,
  isHrUser,
  isFixed,
  setIsFixed,
  successMessage
}) => {
  const { formState, setValue, watch } = useFormContext();

  const onFill = () => {
    const data = {
      nome: 'Joao da Silva',
      email: 'joao@gmail.com',
      telefone_celular: '11999999999',
      cep: '12345678',
      endereco: 'Rua das Flores',
      numero_endereco: '123',
      complemento_endereco: 'Apto 123',
      bairro: 'Jardim das Flores',
      uf: 'SP',
      cidade: 'São Paulo',
      data_nascimento: '01/01/1990',
      peso: '70',
      altura: '170',
      nacionalidade: 'Brasil',
      rg: '123456789',
      orgao_emissor_rg: 'SSP',
      data_expedicao_rg: '01/01/2010',
      sexo: 'Masculino',
      estado_civil: 'Solteiro(a)',
      nome_mae: 'Maria da Silva',
      banco: 'Banco do Brasil S.A.',
      agencia: '1234',
      conta: '123456'
    };

    Object.keys(data).forEach((key) => {
      setValue(key, data[key]);
    });
  };

  const hasValue = watch('nome') ? true : false;
  const cep = watch('cep');

  useEffect(() => {
    const autoCompleteAddress = async () => {
      const unmaskedCep = removeMask(cep);
      if (unmaskedCep && unmaskedCep.length === 8) {
        const data = await service.client.fetchAddress(unmaskedCep);

        const endereco = data?.logradouro;
        const complemento_endereco = data?.complemento;
        const bairro = data?.bairro;
        const uf = data?.uf;
        const cidade = data?.localidade;

        setValue('endereco', endereco);
        setValue('complemento_endereco', complemento_endereco);
        setValue('bairro', bairro);
        setValue('uf', uf);
        setValue('cidade', cidade);
      } else if (!unmaskedCep || unmaskedCep.length !== 8) {
        setValue('endereco', null);
        setValue('complemento_endereco', null);
        setValue('bairro', null);
        setValue('uf', null);
        setValue('cidade', null);
      }
    };

    autoCompleteAddress();
  }, [cep]);

  return (
    <div className="second-step">
      <div className="second-step__form">
        <div className="second-step__container-title">
          <span className="second-step__title">
            Informações gerais do titular
          </span>
        </div>
        {configFields?.nome ? (
          <div>
            <Input
              name="nome"
              validation="name"
              isRequired
              label={'Nome Completo'}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.email ? (
          <div>
            <Input
              name="email"
              label="Email"
              validation="email"
              isRequired
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.telefone_celular ? (
          <div>
            <Input
              isRequired
              mask="phone"
              name="telefone_celular"
              label={'Número de Celular'}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.cep ? (
          <div>
            <Input
              isRequired
              mask="cep"
              name="cep"
              label={'CEP Residencial'}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.endereco ? (
          <div>
            <Input
              isRequired
              name="endereco"
              label={'Endereço'}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.numero_endereco ? (
          <div>
            <Input
              isRequired
              name="numero_endereco"
              label={'Número do Endereço'}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.complemento_endereco ? (
          <div>
            <Input
              name="complemento_endereco"
              label={'Complemento'}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.bairro ? (
          <div>
            <Input
              isRequired
              name="bairro"
              label={'Bairro'}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.uf ? (
          <div>
            <Select
              isRequired
              name="uf"
              label={'Estado'}
              options={estadosBrasileiros.map((item) => ({
                label: item.nome,
                value: item.uf
              }))}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            ></Select>
          </div>
        ) : null}
        {configFields?.cidade ? (
          <div>
            <Input
              name="cidade"
              isRequired
              label={'Cidade'}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.data_nascimento ? (
          <div className="second-step-date">
            <Input
              isRequired
              name="data_nascimento"
              label={'Data de Nascimento'}
              placeholder="DD/MM/AAAA"
              mask="date"
              validation="date"
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.peso && configFields?.altura ? (
          <div className="second-step__imc-container">
            <div>
              <Input
                isRequired
                name="peso"
                label={'Peso (kg)'}
                classNameLabel={'second-step__label'}
                setIsFixed={setIsFixed}
              />
            </div>
            <div>
              <Input
                isRequired
                name="altura"
                label={'Altura (cm)'}
                classNameLabel={'second-step__label'}
                setIsFixed={setIsFixed}
              />
            </div>
          </div>
        ) : null}
        {configFields?.nacionalidade ? (
          <div>
            <Select
              isRequired
              name="nacionalidade"
              label={'Nacionalidade'}
              options={nacionalidades.map((item) => ({
                label: item.nome,
                value: item.nome
              }))}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            ></Select>
          </div>
        ) : null}
        {configFields?.rg ? (
          <div>
            <div>
              <Input
                isRequired
                name="rg"
                label={'RG'}
                classNameLabel={'second-step__label'}
                setIsFixed={setIsFixed}
              />
            </div>
            {configFields?.orgao_emissor_rg &&
            configFields?.data_expedicao_rg ? (
              <div className="second-step-rg-information">
                <div className="second-step-rg-information__text-container">
                  <Input
                    isRequired
                    name="orgao_emissor_rg"
                    label={'Órgão Emissor'}
                    classNameLabel={'second-step__label'}
                    setIsFixed={setIsFixed}
                  />
                </div>
                <div className="second-step-rg-information__date-container">
                  <Input
                    isRequired
                    name="data_expedicao_rg"
                    label={'Data de Expedição'}
                    placeholder="DD/MM/AAAA"
                    mask="date"
                    validation="date"
                    setIsFixed={setIsFixed}
                  />
                </div>
              </div>
            ) : null}
          </div>
        ) : null}
        {configFields?.cpf ? (
          <div>
            <Input
              isRequired
              name="cpf"
              label={'CPF'}
              mask="cpf"
              validation="cpf"
              isDisabled={true}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.sexo ? (
          <div>
            <Select
              isRequired
              name="sexo"
              label={'Gênero'}
              classNameLabel={'second-step__label'}
              options={[
                { label: 'Masculino', value: 'Masculino' },
                { label: 'Feminino', value: 'Feminino' },
                { label: 'Outros', value: 'Outros' }
              ]}
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.estado_civil ? (
          <div>
            <Select
              isRequired
              name="estado_civil"
              label={'Estado Civil'}
              classNameLabel={'second-step__label'}
              options={estadosCivis.map((item) => ({
                label: item.option,
                value: item.value
              }))}
              setIsFixed={setIsFixed}
            ></Select>
          </div>
        ) : null}
        {configFields?.nome_mae ? (
          <div>
            <Input
              isRequired
              name="nome_mae"
              validation="name"
              label={'Nome da Mãe'}
              classNameLabel={'second-step__label'}
              setIsFixed={setIsFixed}
            />
          </div>
        ) : null}
        {configFields?.banco ? (
          <div>
            <p className="field__section">Dados Bancários</p>
            <Select
              isRequired
              name="banco"
              label={'Nome do Banco'}
              classNameLabel={'second-step__label'}
              options={banks.map((item) => ({
                label: `${item.id} - ${item.ShortName}`,
                value: item.LongName
              }))}
              setIsFixed={setIsFixed}
            ></Select>
          </div>
        ) : null}
        {configFields?.agencia && configFields?.conta ? (
          <div className="second-step-bank-information">
            <div>
              <Input
                isRequired
                name="agencia"
                label={'Agência'}
                classNameLabel={'second-step__label'}
                setIsFixed={setIsFixed}
              />
            </div>
            <div>
              <Input
                isRequired
                name="conta"
                label={'Conta'}
                classNameLabel={'second-step__label'}
                setIsFixed={setIsFixed}
              />
            </div>
          </div>
        ) : null}

        {isHrUser && isFixed ? (
          <div className="second-step-save">
            <span className="second-step-save__span">
              Caso tenha feito alguma alteração clique em salvar:
            </span>
            <div>
              <Button
                isDisabled={!formState.isValid}
                type="submit"
                variant="save"
              >
                Salvar
              </Button>
              {successMessage && (
                <span className="second-step-save__span-success">
                  {successMessage}
                </span>
              )}
            </div>
          </div>
        ) : null}

        <div className="second-step__buttons-container">
          <div>
            {isHrUser ? null : (
              <Button type="button" variant="back" onClick={prevStep}>
                Voltar
              </Button>
            )}
          </div>
          <div>
            {isHrUser ? (
              <Button isDisabled={!hasValue} type="button" onClick={nextStep}>
                Avançar
              </Button>
            ) : (
              <Button
                isDisabled={!formState.isValid}
                type="button"
                onClick={nextStep}
              >
                Avançar
              </Button>
            )}

            {isLocalhost() && (
              <Button
                className="form-fill__button"
                type="button"
                onClick={onFill}
              >
                Preencher form
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FormSecondStep;
