import { useEffect, useState } from 'react';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';
import Header from 'apps/hr/components/Header-RH-Novo';
import ReactDatePicker from 'react-datepicker';
import { BsFilter } from 'react-icons/bs';
import { Box, ClickAwayListener, Paper, Popper } from '@material-ui/core';
import { Checkbox } from '@mui/material';
import { IoMdRadioButtonOff, IoMdRadioButtonOn } from 'react-icons/io';
import TableExclusionSchedule from './TableResult';
import Button from 'components/DS/Button';
import api from 'services/api';
import services from 'apps/hr/services';
import { useUser } from 'contexts/user';
import { useHistory } from 'react-router-dom';
import { Pagination } from 'apps/hr/components/Pagination/Pagination';
import moment from 'moment';
import './style.scss';

const ManagerExclusionSchedule = () => {
  const { user } = useUser();
  const queryParams = new URLSearchParams(window.location.search);
  const nameBeneficiary = queryParams.get('beneficiario') ?? '';
  const page = parseInt(queryParams.get('page') ?? 1);
  const history = useHistory();

  const [anchorEl, setAnchorEl] = useState(null);
  const [anchorElDelete, setAnchorElDeletePopover] = useState(null);
  const [contracts, setContracts] = useState([]);
  const [contrato, setContrato] = useState('');
  const [initialDate, setInitialDate] = useState('');
  const [finalDate, setFinalDate] = useState('');
  const [status, setStatus] = useState({
    'Agendamento Criado': true,
    'Movimentação Criada': false,
    'Movimentação Concluída': false
  });
  const [currentPage, setCurrentPage] = useState(page);
  const [totalBeneficiaries, setTotalBeneficiaries] = useState(0);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [nameBeneficiaryFilter, setNameBeneficiaryFilter] =
    useState(nameBeneficiary);

  const handleOpenPopper = (event) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popper' : undefined;

  const handleChangeBeneficiaryName = ({ target }) => {
    setNameBeneficiaryFilter(target.value);
    queryParams.set('beneficiario', target.value);
    window.history.pushState(
      {},
      '',
      `${window.location.pathname}?${queryParams.toString()}`
    );
  };

  const handleContractChange = ({ target }) => {
    setContrato(target.value);
  };

  const handleChangeStatus = (target) => {
    const { value, name, checked } = target;
    setStatus((prevState) => {
      return {
        ...prevState,
        [name]: checked
      };
    });
  };

  const handleClose = () => {
    setAnchorElDeletePopover(null);
  };

  const handleDisplayMessage = () => {
    const beneficiaryToDelete = beneficiaries?.filter(
      (item) => item.servico_agen_id === anchorElDelete?.servico_agen_id
    );
    if (beneficiaryToDelete?.some((item) => item.usuario_rh_id === user.id)) {
      return 'Você quer realmente excluir o agendamento selecionado?';
    } else {
      return 'Este agendamento só poderá ser excluído pelo usuário que o criou.';
    }
  };

  const popupPositiveClickHandler = async () => {
    if (anchorElDelete) {
      await services.scheduleExclusion.deleteScheduledExclusion(
        anchorElDelete.servico_agen_id,
        anchorElDelete.contrato_beneficiario_agendado_id,
        user.mainEstipulante,
        user.id
      );
      setAnchorElDeletePopover(null);
      getExclusionSchedule(currentPage);
    }
  };

  const reloadPage = () => {
    console.log('Página será recarregada...');
    queryParams.set('page', 1);
    window.history.pushState(
      {},
      '',
      `${window.location.pathname}?${queryParams.toString()}`
    );
    getExclusionSchedule(currentPage);
  };

  const getContracts = async () => {
    const response = await api.get('/rh/estipulante-sub/contratos', {
      headers: {
        id: user.mainEstipulante,
        authorization: `Bearer ${user.token}`
      }
    });

    if (response.status === 200) {
      setContracts(response.data?.contratos);
    }
  };

  const formatCompaniesName = (data) => {
    return data.map((item) => ({
      ...item,
      beneficiario: item.beneficiario
        .toLowerCase()
        .split(' ')
        .map((palavra) => palavra.charAt(0).toUpperCase() + palavra.slice(1))
        .join(' ')
    }));
  };

  const getExclusionSchedule = async (page = 1) => {
    const dataInicial = initialDate
      ? moment(initialDate).format('DD-MM-YYYY')
      : '';
    const dataFinal = finalDate ? moment(finalDate).format('DD-MM-YYYY') : '';

    const movCriada = status['Movimentação Criada'];
    const movConcluida = status['Movimentação Concluída'];

    const response = await services.scheduleExclusion.getAllInclusionRequests(
      page,
      contrato,
      nameBeneficiaryFilter,
      dataInicial,
      dataFinal,
      movCriada,
      movConcluida,
      user.mainEstipulante
    );

    if (response.status === 200) {
      setTotalBeneficiaries(response.data.total ?? 0);
      setBeneficiaries(formatCompaniesName(response.data.data));
    }
  };

  const handlePageChange = (currentPage) => {
    if (currentPage < 1 || currentPage > Math.ceil(totalBeneficiaries / 10))
      return;

    queryParams.set('page', currentPage);
    setCurrentPage(currentPage);
    const newURL = `${window.location.pathname}?${queryParams.toString()}`;

    history.push(newURL);
  };

  useEffect(() => {
    getExclusionSchedule(currentPage);
  }, [currentPage, finalDate, contrato, status]);

  useEffect(() => {
    getContracts();
    getExclusionSchedule(currentPage);
  }, []);

  return (
    <div className="manager-exclusion-schedule">
      <SidemenuRh />
      <div className="manager-exclusion-schedule-container">
        <Header />
        <div className="manager-exclusion-schedule-container__main">
          <div className="manager-exclusion-schedule-container__main--painel">
            <div className="manager-exclusion-schedule-title">
              <h1 className="manager-exclusion-schedule-title__title">
                Gestão de Exclusão Agendada
              </h1>
            </div>
            <div className="manager-exclusion-schedule-content">
              <div className="manager-exclusion-schedule-content__input-content">
                <label className="manager-exclusion-schedule-content__label">
                  Consultar por nome
                </label>
                <div className="manager-exclusion-schedule-content__input-group-button">
                  <input
                    className="manager-exclusion-schedule-content__input"
                    type="text"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[0-9]/g, '');
                    }}
                    value={nameBeneficiaryFilter}
                    name="beneficiaryName"
                    placeholder="Pesquisar Beneficiário..."
                    onChange={(e) => handleChangeBeneficiaryName(e)}
                  />
                  <Button
                    size="small"
                    variant="transparent"
                    className="manager-exclusion-schedule-content__button"
                    onClick={() => reloadPage()}
                  >
                    Pesquisar
                  </Button>
                </div>
              </div>

              <div className="manager-exclusion-schedule-content__input-content">
                <label className="manager-exclusion-schedule-content__label-contract">
                  Filtrar por contrato
                </label>
                <div className="manager-exclusion-schedule-content__input-group">
                  <select
                    className="manager-exclusion-schedule-content__select"
                    value={contrato}
                    onChange={handleContractChange}
                    placeholder="Selecione..."
                  >
                    <option value="">Todos</option>
                    {contracts?.map((contract) => (
                      <option value={contract.contrato_id}>
                        {`${contract.seguradora_nome} - ${
                          contract.produto_id === 1 ? 'Saúde' : 'Dental'
                        }`}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="manager-exclusion-schedule-content__input-content">
                <div className="manager-exclusion-schedule-content__period">
                  <label className="manager-exclusion-schedule-content__label">
                    Filtrar por período
                  </label>
                  <BsFilter
                    className="manager-exclusion-schedule-content__icon"
                    aria-describedby={id}
                    size={20}
                    onClick={handleOpenPopper}
                  />
                </div>
                <Popper
                  id="id-periodo"
                  open={open}
                  anchorEl={anchorEl}
                  placement="left-start"
                  transition
                  disablePortal
                >
                  <Box>
                    <ClickAwayListener onClickAway={handleOpenPopper}>
                      <Paper variant="elevation" elevation={3}>
                        <div className="manager-exclusion-schedule-content__period">
                          <div className="manager-exclusion-schedule-content__period-inputs">
                            <label className="manager-exclusion-schedule-content__period-label">
                              Data Inicial:
                            </label>
                            <ReactDatePicker
                              className="manager-exclusion-schedule-content__input-date"
                              selected={initialDate}
                              popperPlacement="bottom-end"
                              placeholderText="..."
                              locale="ptBR"
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setInitialDate(date)}
                            />
                          </div>
                          <div className="manager-exclusion-schedule-content__period-inputs">
                            <label className="manager-exclusion-schedule-content__period-label">
                              Data Final:
                            </label>
                            <ReactDatePicker
                              className="manager-exclusion-schedule-content__input-date"
                              selected={finalDate}
                              disabled={!initialDate}
                              popperPlacement="bottom-start"
                              placeholderText="..."
                              locale="ptBR"
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => setFinalDate(date)}
                            />
                          </div>
                        </div>
                      </Paper>
                    </ClickAwayListener>
                  </Box>
                </Popper>
              </div>
              <div className="manager-exclusion-schedule-content__input-content">
                <label className="manager-exclusion-schedule-content__label">
                  Status
                </label>
                <div className="manager-exclusion-schedule-content__checkbox-group">
                  <div className="manager-exclusion-schedule-content__checkbox">
                    <Checkbox
                      size="small"
                      name="Agendamento Criado"
                      value={status['Agendamento Criado']}
                      icon={<IoMdRadioButtonOff size={16} />}
                      checkedIcon={<IoMdRadioButtonOn size={16} />}
                      checked={status['Agendamento Criado'] === true}
                    />
                    <label className="manager-exclusion-schedule-content__label-checkbox">
                      Agendamento Criado
                    </label>
                  </div>
                  <div className="manager-exclusion-schedule-content__checkbox">
                    <Checkbox
                      size="small"
                      name="Movimentação Criada"
                      value={status['Movimentação Criada']}
                      icon={<IoMdRadioButtonOff size={16} />}
                      checkedIcon={<IoMdRadioButtonOn size={16} />}
                      checked={status['Movimentação Criada'] === true}
                      onChange={({ target }) => handleChangeStatus(target)}
                    />
                    <label className="manager-exclusion-schedule-content__label-checkbox">
                      Movimentação Criada
                    </label>
                  </div>
                  <div className="manager-exclusion-schedule-content__checkbox">
                    <Checkbox
                      size="small"
                      name="Movimentação Concluída"
                      value={status['Movimentação Concluída']}
                      icon={<IoMdRadioButtonOff size={16} />}
                      checkedIcon={<IoMdRadioButtonOn size={16} />}
                      checked={status['Movimentação Concluída'] === true}
                      onChange={({ target }) => handleChangeStatus(target)}
                    />
                    <label className="manager-exclusion-schedule-content__label-checkbox">
                      Movimentação Concluída
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className="manager-exclusion-schedule-content__warning">
              <span>Atenção: A Data Agendada é a data na qual a Innoa irá receber essa demanda para efetuar dentro da operadora.</span>
            </div>
            <div>
              <TableExclusionSchedule
                user={user}
                beneficiaries={beneficiaries}
                anchorElDelete={anchorElDelete}
                setAnchorElDeletePopover={setAnchorElDeletePopover}
                handleDisplayMessage={handleDisplayMessage}
                handleClose={handleClose}
                currentPage={currentPage}
                popupPositiveClickHandler={popupPositiveClickHandler}
                getExclusionSchedule={getExclusionSchedule}
              />
            </div>
            <Pagination
              currentPage={Number(currentPage)}
              totalPages={Math.ceil(totalBeneficiaries / 10)}
              onPageChange={handlePageChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerExclusionSchedule;
