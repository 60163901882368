import { useState } from 'react';
import { ClickAwayListener } from '@material-ui/core';
import Button from 'components/DS/Button';
import InputUploadFile from 'components/DS/InputUploadFile';
import { HiTrash } from 'react-icons/hi';
import services from 'apps/broker/services';
import { toast } from 'react-hot-toast';
import { TbTrashOff } from 'react-icons/tb';
import './style.scss';

const DocumentsList = ({
  setOpenDocumentsList,
  contractInfo,
  hasSendDate,
  faturamentoId,
  documentsList,
  transitionLeave,
  setTransitionLeave,
  getDocumentsList
}) => {
  const [files, setFiles] = useState([]);
  const [message, setMessage] = useState('');

  const handleFile = (target) => {
    if (target.files?.length === 0 || target.files === null) {
      setMessage('Algo deu errado, insira o arquivo novamente.');
      return;
    }
    setFiles([...target.files]);
    setMessage('Clique em salvar');
  };

  const onSubmit = async () => {
    const formData = new FormData();
    files.forEach((file) => {
      formData.append('files', file);
    });
    formData.append('faturamento_id', faturamentoId);

    const response = await services.billing.insertDocument(
      contractInfo.contrato_id,
      formData
    );

    if (response.status === 201) {
      toast.dismiss();
      toast.success('Documento inserido com sucesso.', {
        duration: 2500
      });
      setMessage('');
      getDocumentsList(faturamentoId);
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde.', {
        duration: 2500
      });
    }
  };

  const deleteDocument = async (document_id) => {
    const response = await services.billing.deleteDocument(document_id);
    if (response.status === 204) {
      toast.dismiss();
      toast.success('Documento excluído com sucesso.', {
        duration: 2500
      });
      getDocumentsList(faturamentoId);
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde.', {
        duration: 2500
      });
    }
  };

  return (
    <div
      className="modal-dark-bg-documents-list"
      style={{ animation: transitionLeave ? 'fade-out 0.225s forwards' : '' }}
    >
      <div className="modal-documents-list">
        <ClickAwayListener
          onClickAway={() => {
            setOpenDocumentsList(false);
            setTransitionLeave(true);
          }}
        >
          <div className="documents-list">
            <div className="documents-list__title">
              <h3>Documentos anexados</h3>
            </div>
            <div className="documents-list-content">
              {documentsList.length > 0 &&
                documentsList.map((item) => (
                  <div className="documents-list-item" key={item.id}>
                    <a
                      href={item.file}
                      target="_blank"
                      rel="noreferrer"
                      className="documents-list-item__link"
                    >
                      {item.file_name}
                    </a>
                    <Button
                      variant={
                        hasSendDate.data_envio
                          ? 'transparent-disabled'
                          : 'remove'
                      }
                      onClick={() => deleteDocument(item.id)}
                    >
                      {hasSendDate.data_envio ? (
                        <TbTrashOff size={20} />
                      ) : (
                        <HiTrash size={20} />
                      )}
                    </Button>
                  </div>
                ))}
            </div>
            <div className="documents-list-warning">
              {message !== '' && (
                <span className="documents-list-warning__span">{message}</span>
              )}
            </div>
            <div className="documents-list-buttons">
              <InputUploadFile
                variant={hasSendDate.data_envio ? 'disabled' : 'broker'}
                label="+ Documentos"
                onChange={(event) => handleFile(event.target)}
              />
              <Button
                variant={hasSendDate.data_envio ? 'disabled' : 'save'}
                size="small"
                onClick={() => onSubmit()}
              >
                Salvar
              </Button>
            </div>
          </div>
        </ClickAwayListener>
      </div>
    </div>
  );
};

export default DocumentsList;
