import PopperDeleteConfirm from '../../ManagerRequestInclusion/popperDeleteConfirmation';
import CardExclusionSchedule from './Cards';
import './style.scss';

const TableExclusionSchedule = ({
  user,
  beneficiaries,
  anchorElDelete,
  setAnchorElDeletePopover,
  handleDisplayMessage,
  handleClose,
  currentPage,
  popupPositiveClickHandler,
  getExclusionSchedule
}) => {
  return (
    <table className="table-exclusion-schedule">
      <thead>
        <tr className="table-exclusion-schedule__thead-tr">
          <th>Nome do beneficiário</th>
          <th>Plano</th>
          <th>Produto</th>
          <th>Contrato</th>
          <th>Número Apólice</th>
          <th>Data Agendada</th>
          <th>Mov. Criada</th>
          <th>Mov. Conc.</th>
          <th>
            <div className="table-exclusion-schedule__thead-tr--modal-delete">
              <PopperDeleteConfirm
                showOptions={
                  anchorElDelete?.usuario_rh_id === user.id ? true : false
                }
                anchorElDelete={anchorElDelete}
                handleClose={handleClose}
                handleDisplayMessage={handleDisplayMessage}
                popupPositiveClickHandler={popupPositiveClickHandler}
              />
            </div>
          </th>
        </tr>
      </thead>
      <tbody>
        {beneficiaries?.map((item, index) => (
          <CardExclusionSchedule
            user={user}
            item={item}
            index={index}
            setAnchorElDeletePopover={setAnchorElDeletePopover}
            currentPage={currentPage}
            getExclusionSchedule={getExclusionSchedule}
          />
        ))}
      </tbody>
    </table>
  );
};

export default TableExclusionSchedule;
