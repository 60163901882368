import http from 'services/http';

const updateEmailPreferences = async (body) => {
  const { emailType, usuario_rh_id } = body;
  const res = await http.patch('/email-preferences', { emailType, usuario_rh_id });
  return res;
};

const getEmailPreferences = async (userHrId) => {
  const res = await http.get(`/email-preferences/${userHrId}`);
  return res.data;
};

const modules = {
  updateEmailPreferences,
  getEmailPreferences
};

export default modules;
