import Button from 'components/DS/Button';
import { BiEdit } from 'react-icons/bi';
import { VscCheck, VscChromeClose } from 'react-icons/vsc';
import './style.scss';

const ModalEditMessage = ({
  openEditTextarea,
  setOpenEditTextarea,
  openMessageModal,
  setOpenMessageModal,
  newData,
  handleBillingDataChange,
  updateBillingData
}) => {
  return (
    <div
      className="modal-dark-bg-edit-message"
      style={{
        animation: openMessageModal.id === 0 ? 'fade-out 0.225s forwards' : ''
      }}
    >
      <div className="modal-edit-message">
        <div className="modal-edit-message-content">
          <div className="modal-edit-message-content__header">
            <div className="modal-edit-message-content__title">
              <h3>Editar Mensagem</h3>
            </div>
            <Button
              variant="remove"
              onClick={() => {
                setOpenEditTextarea(false);
                setOpenMessageModal({ id: 0, mensagem: '' });
              }}
            >
              X
            </Button>
          </div>
          <div>
            {openEditTextarea ? (
              <div className="modal-edit-message-content__form">
                <textarea
                  className="modal-edit-message-content__textarea"
                  value={newData}
                  onChange={handleBillingDataChange}
                />
                <div className="modal-edit-message-content__buttons-container">
                  <Button
                    variant="save"
                    size="small"
                    onClick={() =>
                      updateBillingData(
                        openMessageModal.id,
                        'mensagem',
                        newData
                      )
                    }
                  >
                    <VscCheck size={18} />
                    Salvar
                  </Button>
                  <Button
                    variant="cancel"
                    size="small"
                    onClick={() => setOpenEditTextarea(false)}
                  >
                    <VscChromeClose size={18} />
                    Cancelar
                  </Button>
                </div>
              </div>
            ) : (
              <div className="modal-edit-message-content__form">
                <textarea
                  className="modal-edit-message-content__textarea"
                  value={openMessageModal.mensagem}
                  disabled={true}
                />
                {openMessageModal.data_envio ? (
                  <span className="modal-edit-message-content__textarea--warning">
                    A mensagem não pode ser editada após o envio da fatura.
                  </span>
                ) : (
                  <Button
                    variant="transparent"
                    size="medium"
                    onClick={() => setOpenEditTextarea(true)}
                  >
                    <BiEdit size={18} />
                    Editar Mensagem
                  </Button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ModalEditMessage;
