import Popper from '@material-ui/core/Popper';
import Paper from '@material-ui/core/Paper';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Box from '@mui/material/Box';
import { VscChromeClose, VscCheck } from 'react-icons/vsc';
import './style.scss';

const PopperDeleteConfirm = ({
  showOptions = true,
  anchorElDelete,
  handleClose,
  handleDisplayMessage,
  popupPositiveClickHandler
}) => {
  return (
    <Popper
      id={'id'}
      open={Boolean(anchorElDelete)}
      anchorEl={anchorElDelete}
      transition
      disablePortal
    >
      <Box>
        <ClickAwayListener onClickAway={handleClose}>
          <Paper elevation={3} className="popper-delete-confirmation">
            <span className="popper-delete-confirmation__message">
              {handleDisplayMessage()}
            </span>
            {showOptions ? (
              <div className="popper-options-container">
                <span
                  className="popper-options-container__options"
                  onClick={popupPositiveClickHandler}
                >
                  <VscCheck
                    className="popper-options-container__check-icon"
                    size={12}
                  />
                  Sim
                </span>
                <span
                  className="popper-options-container__options"
                  onClick={handleClose}
                >
                  <VscChromeClose
                    className="popper-options-container__cross-icon"
                    size={12}
                  />
                  Não
                </span>
              </div>
            ) : null}
          </Paper>
        </ClickAwayListener>
      </Box>
    </Popper>
  );
};

export default PopperDeleteConfirm;
