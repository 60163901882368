import React, { useState } from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, useHistory } from 'react-router-dom';
import Backdrop from '@material-ui/core/Backdrop';
import ReactDatePicker from 'react-datepicker';
import { FaSave } from 'react-icons/fa';
import services from 'apps/broker/services';
import Button from 'components/DS/Button';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '1rem',
    '& > * + *': { marginLeft: theme.spacing(2) }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 10,
    color: '#fff'
  },
  load: { color: '#6e3ec1' }
}));

const DadosEstipulante = () => {
  const classes = useStyles();
  const history = useHistory();

  const [dadosReceita, setDadosReceita] = useState({});
  const [loadCnpj, setLoadCnpj] = useState(false);
  const [loadApi, setLoadApi] = useState(false);

  const { register, handleSubmit, setValue, getValues, control, watch } =
    useForm({
      defaultValues: {
        subEstipulante: [{ cnpj: '' }],
        emails: [{ email: '' }],
        gestores: [{ nome: '' }]
      }
    });

  const {
    fields: subEstipulanteFields,
    append: subEstipulanteAppend,
    remove: subEstipulanteRemove
  } = useFieldArray({ control, name: 'subEstipulante' });
  const {
    fields: emailsFields,
    append: emailsAppend,
    remove: emailsRemove
  } = useFieldArray({ control, name: 'emails' });
  const {
    fields: gestorFields,
    append: gestorAppend,
    remove: gestorRemove
  } = useFieldArray({ control, name: 'gestores' });

  async function cnpjSend(value) {
    try {
      if (value.length > 17) {
        setLoadCnpj(true);
        const cnpj = value.replace(/[^\d]+/g, '');

        await services.policyOwner.getRevenueService(cnpj).then((response) => {
          const {
            nome,
            abertura,
            logradouro,
            numero,
            complemento,
            cep,
            uf,
            municipio
          } = response.data;
          const complementoTratado = complemento.replace(';', '');
          setDadosReceita({
            nome,
            abertura,
            logradouro,
            numero,
            complemento: complementoTratado,
            cep,
            uf,
            municipio
          });
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoadCnpj(false);
    }
  }

  async function subsCnpjSend(value, index) {
    try {
      if (value.length > 17) {
        const cnpj = value.replace(/[^\d]+/g, '');

        await services.policyOwner.getRevenueService(cnpj).then((response) => {
          const {
            nome,
            abertura,
            logradouro,
            numero,
            complemento,
            cep,
            uf,
            municipio
          } = response.data;
          const complementoTratado = complemento.replace(';', '');

          setValue(`subEstipulante.${index}.dados`, {
            nome,
            abertura,
            logradouro,
            numero,
            complemento: complementoTratado,
            cep,
            uf,
            municipio
          });
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  }

  const onSubmit = (data) => {
    setLoadApi(true);

    const body = {
      ...data,
      ...dadosReceita
    };

    services.policyOwner.createPolicyOwner(body)
      .then((resp) => {
        if (resp.status === 200) {
          return history.push("/corretor/estipulantes");
        }
      })
      .catch((err) => {
        return err;
      })
      .finally(() => {
        setLoadApi(false);
      });
  };

  const handleCnpjChange = (field, target) => {
    field.onChange(target.value);
    if (target.value.length > 17) {
      cnpjSend(target.value);
    }
  };
  const handleSubsCnpjChange = (field, target, index) => {
    field.onChange(target.value);
    if (target.value.length > 17) {
      subsCnpjSend(target.value, index);
    }
  };

  const handleRadioChange = ({ target }) => {
    setValue('possui-subEstipulante', target.value);
    if (target.value === 'não') {
      setValue('subEstipulante', [{ cnpj: '' }]);
    }
  };

  return (
    <>
      <Backdrop
        className={classes.backdrop}
        open={loadApi}
        onClick={() => setLoadApi(false)}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div>
        <form
          className="register-policy-owner-form"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="register-policy-owner-form-grids">
            <div className="register-policy-owner-form-col">
              <div className="register-policy-owner-form-input-group">
                <label>CNPJ</label>
                <Controller
                  name="cnpj"
                  control={control}
                  rules={{ required: false }}
                  render={({ field }) => (
                    <InputMask
                      value={field.value || ''}
                      maskChar={null}
                      mask="99.999.999/9999-99"
                      type="text"
                      onChange={({ target }) => handleCnpjChange(field, target)}
                    />
                  )}
                />
                {loadCnpj && (
                  <div className={classes.root}>
                    <CircularProgress size={20} className={classes.load} />
                  </div>
                )}

                {dadosReceita?.nome && (
                  <div className="dados-receita">
                    {dadosReceita.nome} <br />
                    {dadosReceita.logradouro} {dadosReceita.numero}{' '}
                    {dadosReceita?.complemento} <br />
                    {dadosReceita.cep} {dadosReceita.municipio} -{' '}
                    {dadosReceita.uf} <br />
                  </div>
                )}
              </div>

              <div className="register-policy-owner-form-input-group">
                <label>Data de inicio de vínculo com este Estipulante</label>
                <Controller
                  name={`dataEstipulante`}
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { onChange, value } }) => (
                    <ReactDatePicker
                      className="input-data"
                      selected={value}
                      locale="ptBR"
                      onChange={onChange}
                      dateFormat="dd/MM/yyyy"
                    />
                  )}
                />
              </div>

              <div className="register-policy-owner-form-checkbox-area">
                <label>Esse estipulante possui sub-estipulantes?</label>
                <div className="register-policy-owner-form-checkbox-group">
                  <label
                    htmlFor="sim"
                    className={
                      watch('possui-subEstipulante') === 'sim'
                        ? 'select-label active'
                        : 'select-label'
                    }
                  >
                    Sim
                  </label>
                  <input
                    id="sim"
                    type="radio"
                    value="sim"
                    {...register('possui-subEstipulante', { required: false })}
                    onChange={(e) => handleRadioChange(e)}
                  />
                </div>
                <div className="register-policy-owner-form-checkbox-group">
                  <label
                    htmlFor="nao"
                    className={
                      watch('possui-subEstipulante') === 'não'
                        ? 'select-label active'
                        : 'select-label'
                    }
                  >
                    Não
                  </label>
                  <input
                    id="nao"
                    type="radio"
                    value="não"
                    {...register('possui-subEstipulante', { required: false })}
                    onChange={(e) => handleRadioChange(e)}
                  />
                </div>
              </div>

              {watch('possui-subEstipulante') === 'sim' && (
                <div className="register-policy-owner-form-input-group">
                  {subEstipulanteFields.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="register-policy-owner-form-input-group__area-sub"
                      >
                        <div className="register-policy-owner-form-input-group__label-sub">
                          <label>Insira o CNPJ da Sub-estipulante</label>
                          {index !== 0 && (
                            <Button
                              type="button"
                              variant="remove"
                              size="small"
                              onClick={() => subEstipulanteRemove(index)}
                            >
                              remover
                            </Button>
                          )}
                        </div>

                        <Controller
                          name={`${item.subEstipulante?.[index].cnpj}`}
                          control={control}
                          rules={{ required: false }}
                          defaultValue={`${item.subEstipulante?.[index].cnpj}`}
                          render={({ field }) => (
                            <InputMask
                              {...field}
                              maskChar={null}
                              mask="99.999.999/9999-99"
                              type="text"
                              onChange={({ target }) =>
                                handleSubsCnpjChange(field, target, index)
                              }
                            />
                          )}
                        />

                        {watch(`subEstipulante.${index}.cnpj`).length === 18 &&
                          !watch(`subEstipulante.${index}.dados.nome`) && (
                            <CircularProgress size={20} />
                          )}

                        {watch(`subEstipulante.${index}.dados`) && (
                          <div className="dados-receita">
                            {getValues(`subEstipulante.${index}.dados.nome`)}
                          </div>
                        )}
                      </div>
                    );
                  })}
                  <div className="register-policy-owner-form-input-group__button">
                    <Button
                      type="button"
                      size="small"
                      onClick={() => subEstipulanteAppend({ cnpj: '' })}
                    >
                      + Sub estipulante
                    </Button>
                  </div>
                </div>
              )}
            </div>

            <div className="register-policy-owner-form-col">
              <div className="register-policy-owner-form-input-group">
                <label>E-mails da empresa</label>
                <div className="register-policy-owner-form-input-group__legend">
                  Cadastre a terminação do e-mail da empresa. Dessa forma, todos
                  tickets enviados por essa terminação de e-mail serão agrupados
                  para esse estipulante.
                </div>

                {emailsFields.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="register-policy-owner-form-input-group__area-sub"
                    >
                      <div className="register-policy-owner-form-input-group__button-remove">
                        {index !== 0 && (
                          <Button
                            type="button"
                            variant="remove"
                            size="small"
                            onClick={() => emailsRemove(index)}
                          >
                            remover
                          </Button>
                        )}
                      </div>
                      <div>
                        <span>@</span>
                        <input
                          type="text"
                          name={`emails${index}.email`}
                          {...register(`emails.${index}.email`, {
                            required: false
                          })}
                        />
                      </div>
                    </div>
                  );
                })}
              </div>
              <div className="register-policy-owner-form-input-group__button">
                <Button
                  type="button"
                  size="small"
                  onClick={() => emailsAppend({ email: '' })}
                >
                  + Adicionar e-mail
                </Button>
              </div>
            </div>

            <div className="register-policy-owner-form-col">
              <div className="register-policy-owner-form-input-group">
                <label>Gestores do Estipulante</label>
                <div className="register-policy-owner-form-input-group__legend">
                  Adicione os gestores do estipulante. Eles terão acesso ao
                  painel do RH.
                </div>
                {gestorFields.map((item, index) => {
                  return (
                    <div
                      key={index}
                      className="register-policy-owner-form-input-group__area-sub"
                    >
                      <div className="register-policy-owner-form-input-group__button-remove">
                        {index !== 0 && (
                          <Button
                            type="button"
                            variant="remove"
                            size="small"
                            onClick={() => gestorRemove(index)}
                          >
                            remover
                          </Button>
                        )}
                      </div>
                      <input
                        type="text"
                        name={`gestores${index}.nome`}
                        {...register(`gestores.${index}.nome`, {
                          required: false
                        })}
                      />
                    </div>
                  );
                })}
              </div>
              <div className="register-policy-owner-form-input-group__button">
                <Button
                  type="button"
                  size="small"
                  onClick={() => gestorAppend({ nome: '' })}
                >
                  + Adicionar gestor
                </Button>
              </div>
            </div>
          </div>

          <div className="register-policy-owner-form-input-group__button-register">
            <div>
              <Button variant="back" size="small">
                <Link className="register-policy-owner-form-input-group__link" to="/corretor/estipulantes">Voltar</Link>
              </Button>
            </div>
            <div>
              <Button variant="save" size="small" type="submit">
                <FaSave />
                Salvar
              </Button>
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default DadosEstipulante;
