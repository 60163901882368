import http from 'services/http';

const getAllInclusionRequests = (
  page,
  contract,
  beneficiaryName,
  initialDate,
  finalDate,
  movCriada,
  movConcluida,
  estipulanteId
) => {
  const res = http.get(
    `/scheduled-exclusion/?page=${page}&contrato=${contract}&beneficiario=${beneficiaryName}&data_inicial=${initialDate}&data_final=${finalDate}&mov_criada=${movCriada}&mov_conc=${movConcluida}`,
    {
      headers: {
        id: estipulanteId
      }
    }
  );
  return res;
};

const deleteScheduledExclusion = (
  scheduleId,
  contractBeneficiaryScheduleId,
  estipulanteId,
  usuarioId
) => {
  const res = http.delete(
    `/scheduled-exclusion/${scheduleId}/${contractBeneficiaryScheduleId}`,
    {
      headers: {
        id: estipulanteId,
        usuario_rh_id: usuarioId
      }
    }
  );

  return res;
};

const updateScheduleDate = (
  scheduleId,
  newDateExclusion,
  estipulanteId,
  usuarioId
) => {
  const res = http.patch(
    `/scheduled-exclusion/${scheduleId}`,
    {
      newDateExclusion
    },
    {
      headers: {
        id: estipulanteId,
        usuario_rh_id: usuarioId
      }
    }
  );

  return res;
};

const modules = {
  getAllInclusionRequests,
  deleteScheduledExclusion,
  updateScheduleDate
};

export default modules;
