import React, { useState, useEffect } from 'react';
import HeaderRh from 'apps/hr/components/Header-RH-Novo';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';
import MainRh from 'apps/hr/components/MainContentRh';
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai';
import { FaRegQuestionCircle } from 'react-icons/fa';
import { RiSendPlaneFill } from 'react-icons/ri';
import colors from 'styles/colors';
import { usePostHog } from 'posthog-js/react';
import { useUser } from 'contexts/user';
import Button from 'components/DS/Button';
import { Checkbox } from '@mui/material';
import api from 'services/api';
import services from 'apps/hr/services';
import './style.scss';

const TrocarSenha = () => {
  const posthog = usePostHog();
  const { user } = useUser();

  const [showPassword, setShowPassword] = useState({
    senhaAntiga: false,
    novaSenha: false,
    confirmarSenha: false
  });

  const [senhaAntiga, setSenhaAntiga] = useState('');
  const [senhaNova, setSenhaNova] = useState('');
  const [confirmarSenha, setConfirmarSenha] = useState('');
  const [senhasDiferentes, setSenhasDiferentes] = useState(false);
  const [showResponseMessage, setShowResponseMessage] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [responseMessage, setResponseMessage] = useState('');
  const [emailsPreferences, setEmailsPreferences] = useState({});
  const [informationPopUp, setInformationPopUp] = useState('');

  const toggleShowPassword = (name) => {
    setShowPassword((prevState) => ({
      ...prevState,
      [name]: !prevState[name]
    }));
  };

  const getEmailPreferences = async () => {
    const response = await services.settings.getEmailPreferences(user.id);
    setEmailsPreferences({ ...response });
  };

  const updateEmailPreferencesChangeHandler = async ({ target }) => {
    const { id, emailType, created_at, updated_at, ...rest } =
      emailsPreferences;

    const body = {
      emailType: {
        ...rest,
        [target.value]: target.checked ? 1 : 0
      },
      usuario_rh_id: user.id
    };
    const response = await services.settings.updateEmailPreferences(body);

    if (response.status === 200) {
      getEmailPreferences();
    }
  };

  const requestPasswordChange = () => {
    if (senhaNova !== confirmarSenha) {
      setSenhasDiferentes(true);
      setShowErrorMessage(true);
      setResponseMessage('Nova senha e sua confirmação precisam ser iguais');
      setShowResponseMessage(true);
      setTimeout(() => {
        setShowResponseMessage(false);
        setShowErrorMessage(false);
        setResponseMessage('');
      }, 3500);
      return;
    }
    setSenhasDiferentes(false);

    const body = {
      usuario: user,
      senhaAntiga,
      senhaNova
    };

    const headers = {
      authorization: `Bearer ${user.token}`
    };

    api.post('/rh/usuarios/trocar-senha', body, { headers }).then((resp) => {
      // console.log(resp);
      if (resp.data.message) {
        setSenhasDiferentes(true);
        setShowErrorMessage(resp.data.status !== 200 ? true : false);
        setResponseMessage(resp.data.message);
        setShowResponseMessage(true);
        setTimeout(() => {
          setShowResponseMessage(false);
          setShowErrorMessage(false);
          setResponseMessage('');
        }, 3500);
      }
    });
  };

  // Se senha estiver errada na requisição, quando estiverem iguais não mostrar mais mensagem de erro

  if (senhasDiferentes) {
    if (senhaNova === confirmarSenha) {
      setSenhasDiferentes(false);
    }
  }

  useEffect(() => {
    posthog?.capture('Acessou "/rh/trocar-senha"');
    getEmailPreferences();
  }, []);

  return (
    <div className="hr-change-password">
      <SidemenuRh />
      <div className="hr-change-password-content">
        <HeaderRh />
        <MainRh>
          <div className="hr-change-password-container">
            <form>
              <div className="hr-change-password-consult">
                <div className="hr-change-password-consult__title">
                  <span>Altere sua senha:</span>
                </div>
              </div>
              <section className="hr-change-password-inputs-container">
                <div className="hr-change-password-inputs-container__content">
                  <label className="hr-change-password-inputs-container__label">
                    Senha atual
                  </label>
                  <div className="input-icon">
                    <div>
                      <input
                        className="hr-change-password-inputs-container__input"
                        type={showPassword['senhaAntiga'] ? 'text' : 'password'}
                        value={senhaAntiga}
                        placeholder=""
                        onChange={(e) => setSenhaAntiga(e.target.value)}
                        autoComplete="new-password"
                      />
                    </div>
                    <div>
                      {showPassword['senhaAntiga'] ? (
                        <AiFillEyeInvisible
                          className="hr-change-password-inputs-container__icon"
                          name="senhaAntiga"
                          onClick={() => toggleShowPassword('senhaAntiga')}
                          size={20}
                        />
                      ) : (
                        <AiFillEye
                          className="hr-change-password-inputs-container__icon"
                          name="senhaAntiga"
                          onClick={() => toggleShowPassword('senhaAntiga')}
                          size={20}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="hr-change-password-inputs-container__content">
                  <label className="hr-change-password-inputs-container__label">
                    Nova senha
                  </label>
                  <div className="input-icon">
                    <div>
                      <input
                        className="hr-change-password-inputs-container__input"
                        style={{
                          border:
                            senhasDiferentes && `1.5px ${colors.red} solid`
                        }}
                        value={senhaNova}
                        onChange={(e) => setSenhaNova(e.target.value)}
                        placeholder=""
                        type={showPassword['novaSenha'] ? 'text' : 'password'}
                      />
                    </div>
                    <div>
                      {showPassword['novaSenha'] ? (
                        <AiFillEyeInvisible
                          className="hr-change-password-inputs-container__icon"
                          name="novaSenha"
                          onClick={() => toggleShowPassword('novaSenha')}
                          size={20}
                        />
                      ) : (
                        <AiFillEye
                          className="hr-change-password-inputs-container__icon"
                          name="novaSenha"
                          onClick={() => toggleShowPassword('novaSenha')}
                          size={20}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="hr-change-password-inputs-container__content">
                  <label className="hr-change-password-inputs-container__label">
                    Confirmar nova senha
                  </label>
                  <div className="input-icon">
                    <div>
                      <input
                        className="hr-change-password-inputs-container__input"
                        style={{
                          border:
                            senhasDiferentes && `1.5px ${colors.red} solid`
                        }}
                        value={confirmarSenha}
                        placeholder=""
                        onChange={(e) => setConfirmarSenha(e.target.value)}
                        type={
                          showPassword['confirmarSenha'] ? 'text' : 'password'
                        }
                      />
                    </div>
                    <div>
                      {showPassword['confirmarSenha'] ? (
                        <AiFillEyeInvisible
                          className="hr-change-password-inputs-container__icon"
                          onClick={() => toggleShowPassword('confirmarSenha')}
                          size={20}
                        />
                      ) : (
                        <AiFillEye
                          className="hr-change-password-inputs-container__icon"
                          onClick={() => toggleShowPassword('confirmarSenha')}
                          size={20}
                        />
                      )}
                    </div>
                  </div>
                </div>
                <Button
                  variant="transparent"
                  size="small"
                  onClick={requestPasswordChange}
                >
                  <RiSendPlaneFill size={20} />
                  Alterar senha
                </Button>
                <div>
                  {showResponseMessage ? (
                    <span
                      className={
                        showErrorMessage
                          ? 'error-message-span'
                          : 'confirmation-message-span'
                      }
                    >
                      {responseMessage}
                    </span>
                  ) : (
                    ''
                  )}
                </div>
              </section>
            </form>
            <div className="hr-change-pass-veritical-bar"></div>
            <div className="settings-emails">
              <div className="hr-change-password-consult">
                <div className="hr-change-password-consult__title">
                  <span>
                    Selecione as notificações que você deseja receber:
                  </span>
                </div>
              </div>
              <div className="settings-emails-notifications-list">
                <div>
                  <Checkbox
                    size="small"
                    value="ticket_aberto"
                    sx={{
                      color: '#02024e',
                      '&.Mui-checked': {
                        color: '#02024e'
                      }
                    }}
                    onChange={updateEmailPreferencesChangeHandler}
                    checked={Boolean(emailsPreferences['ticket_aberto'])}
                  />
                  <label>Ticket Aberto</label>
                  <div
                    className="settings-emails-notifications-list-informative-mark"
                    onMouseEnter={() => setInformationPopUp('ticket_aberto')}
                    onMouseLeave={() => setInformationPopUp('')}
                  >
                    <FaRegQuestionCircle className="settings-emails-notifications-list-informative-mark__icon-question" />
                    {informationPopUp === 'ticket_aberto' && (
                      <div className="settings-emails-notifications-list-informative-mark__information-pop-up">
                        <span>
                          E-mail enviado quando um novo ticket é aberto.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <Checkbox
                    size="small"
                    value="ticket_arquivado"
                    sx={{
                      color: '#02024e',
                      '&.Mui-checked': {
                        color: '#02024e'
                      }
                    }}
                    onChange={updateEmailPreferencesChangeHandler}
                    checked={Boolean(emailsPreferences['ticket_arquivado'])}
                  />
                  <label>Ticket Arquivado</label>
                  <div
                    className="settings-emails-notifications-list-informative-mark"
                    onMouseEnter={() => setInformationPopUp('ticket_arquivado')}
                    onMouseLeave={() => setInformationPopUp('')}
                  >
                    <FaRegQuestionCircle className="settings-emails-notifications-list-informative-mark__icon-question" />
                    {informationPopUp === 'ticket_arquivado' && (
                      <div className="settings-emails-notifications-list-informative-mark__information-pop-up">
                        <span>
                          E-mail enviado quando um ticket é arquivado.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <Checkbox
                    size="small"
                    value="ticket_resolvido"
                    sx={{
                      color: '#02024e',
                      '&.Mui-checked': {
                        color: '#02024e'
                      }
                    }}
                    onChange={updateEmailPreferencesChangeHandler}
                    checked={Boolean(emailsPreferences['ticket_resolvido'])}
                  />
                  <label>Ticket Resolvido</label>
                  <div
                    className="settings-emails-notifications-list-informative-mark"
                    onMouseEnter={() => setInformationPopUp('ticket_resolvido')}
                    onMouseLeave={() => setInformationPopUp('')}
                  >
                    <FaRegQuestionCircle className="settings-emails-notifications-list-informative-mark__icon-question" />
                    {informationPopUp === 'ticket_resolvido' && (
                      <div className="settings-emails-notifications-list-informative-mark__information-pop-up">
                        <span>
                          E-mail enviado quando um ticket é resolvido.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <Checkbox
                    size="small"
                    value="inclusao"
                    sx={{
                      color: '#02024e',
                      '&.Mui-checked': {
                        color: '#02024e'
                      }
                    }}
                    onChange={updateEmailPreferencesChangeHandler}
                    checked={Boolean(emailsPreferences['inclusao'])}
                  />
                  <label>
                    Movimentação Inclusão de Beneficiário via link de cadastro
                  </label>
                  <div
                    className="settings-emails-notifications-list-informative-mark"
                    onMouseEnter={() => setInformationPopUp('inclusao')}
                    onMouseLeave={() => setInformationPopUp('')}
                  >
                    <FaRegQuestionCircle className="settings-emails-notifications-list-informative-mark__icon-question" />
                    {informationPopUp === 'inclusao' && (
                      <div className="settings-emails-notifications-list-informative-mark__information-pop-up">
                        <span>
                          E-mail enviado quando um novo beneficiário é incluso
                          no plano.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <Checkbox
                    size="small"
                    value="movimentacao_inclusao"
                    sx={{
                      color: '#02024e',
                      '&.Mui-checked': {
                        color: '#02024e'
                      }
                    }}
                    onChange={updateEmailPreferencesChangeHandler}
                    checked={Boolean(
                      emailsPreferences['movimentacao_inclusao']
                    )}
                  />
                  <label>
                    Movimentação Inclusão de Beneficiário via Zeca RH
                  </label>
                  <div
                    className="settings-emails-notifications-list-informative-mark"
                    onMouseEnter={() =>
                      setInformationPopUp('movimentacao_inclusao')
                    }
                    onMouseLeave={() => setInformationPopUp('')}
                  >
                    <FaRegQuestionCircle className="settings-emails-notifications-list-informative-mark__icon-question" />
                    {informationPopUp === 'movimentacao_inclusao' && (
                      <div className="settings-emails-notifications-list-informative-mark__information-pop-up">
                        <span>
                          E-mail enviado quando um novo beneficiário é incluso
                          no plano.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
                <div>
                  <Checkbox
                    size="small"
                    value="exclusao_beneficiario"
                    sx={{
                      color: '#02024e',
                      '&.Mui-checked': {
                        color: '#02024e'
                      }
                    }}
                    onChange={updateEmailPreferencesChangeHandler}
                    checked={Boolean(
                      emailsPreferences['exclusao_beneficiario']
                    )}
                  />
                  <label>Movimentação Exclusão de Beneficiário</label>
                  <div
                    className="settings-emails-notifications-list-informative-mark"
                    onMouseEnter={() =>
                      setInformationPopUp('exclusao_beneficiario')
                    }
                    onMouseLeave={() => setInformationPopUp('')}
                  >
                    <FaRegQuestionCircle className="settings-emails-notifications-list-informative-mark__icon-question" />
                    {informationPopUp === 'exclusao_beneficiario' && (
                      <div className="settings-emails-notifications-list-informative-mark__information-pop-up">
                        <span>
                          E-mail enviado quando um beneficiário é excluído do
                          plano.
                        </span>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </MainRh>
      </div>
    </div>
  );
};

export default TrocarSenha;
