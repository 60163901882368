import styled from "styled-components";

export const Container = styled.div`
  overflow: auto;

  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @media (max-height: 580px) {
    background: white;
    padding: 13.4px;
    padding-left: 30.15px;
    padding-right: 30.15px;
  }

  @media (min-height: 580px) and (max-height: 650px) {
    background: white;
    padding: 16px;
    padding-left: 36px;
    padding-right: 36px;
  }

  @media (min-height: 650px) and (max-height: 720px) {
    background: white;
    padding: 18px;
    padding-left: 40.5px;
    padding-right: 40.5px;
  }

  @media (min-height: 720px) and (max-height: 1000px) {
    background: white;
    padding: 20px;
    padding-left: 45px;
    padding-right: 45px;
  }

  @media (min-height: 1000px) and (max-height: 1500px) {
    background: white;
    padding: 22px;
    padding-left: 50px;
    padding-right: 50px;
  }

  @media (min-height: 1500px) and (max-height: 1900px) {
    height: 100vh;
    background: white;
    padding: 24px;
    padding-left: 55px;
    padding-right: 55px;
  }
`;
