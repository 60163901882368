import { useEffect, useMemo, useState } from 'react';
import SidemenuRh from 'apps/hr/components/Sidebar-RH';
import Header from 'apps/hr/components/Header-RH-Novo';
import CheckmarkStandard from 'apps/broker/components/Select/Checkmark';
import TableHeader from './TableHeader';
import TableResult from './TableResult';
import { useInclusionRequests } from 'contexts/inclusionRequests';
import { useUser } from 'contexts/user';
import DownloadPersonalInformation from './DownloadPersonalInformation';
import service from 'apps/hr/services';
import './style.scss';

const ManagerRequestInclusion = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const nameBeneficiary = queryParams.get('name') ?? '';

  const { user } = useUser();
  const {
    holdersInformation,
    statusRequests,
    status,
    setStatus,
    getInclusionRequests
  } = useInclusionRequests();

  const [personalInformation, setPersonalInformation] = useState([]);
  const [anchorElDelete, setAnchorElDeletePopover] = useState(null);
  const [nameBeneficiaryFilter, setNameBeneficiaryFilter] =
    useState(nameBeneficiary);

  const handleClose = () => {
    setAnchorElDeletePopover(null);
  };

  const popupPositiveClickHandler = async () => {
    if (anchorElDelete) {
      await service.inclusionRequest.deleteRequestInclusion(anchorElDelete);
      setAnchorElDeletePopover(null);
      getInclusionRequests(nameBeneficiaryFilter);
    }
  };

  const openDeleteModalHandler = (holderId) => {
    const requestId = Number(holderId);
    setAnchorElDeletePopover(requestId);
  };

  const handleDisplayMessage = () => {
    return 'Você quer realmente excluir o item selecionado?';
  };

  const getPersonalInformationToDownload = async () => {
    const titulares = await service.inclusionRequest.getPersonalInformation(
      user.estipulante
    );

    if (titulares.length > 0) {
      return;
    }

    setPersonalInformation(titulares);
  };

  const handleAllFilterNameBeneficiary = ({ target }) => {
    setNameBeneficiaryFilter(target.value);

    if (!target.value) {
      queryParams.delete('name');
    }

    queryParams.set('name', target.value);
    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${queryParams.toString()}`
    );
  };

  const filteredBeneficiariesByName = useMemo(() => {
    if (!nameBeneficiaryFilter) return holdersInformation;

    return holdersInformation.filter((beneficiary) =>
      beneficiary.titular_nome
        .toLowerCase()
        .includes(nameBeneficiaryFilter.toLowerCase())
    );
  }, [nameBeneficiaryFilter, holdersInformation]);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getInclusionRequests(nameBeneficiaryFilter);
      getPersonalInformationToDownload();
    }, 500);

    return () => clearTimeout(timeoutId);
  }, [nameBeneficiaryFilter, getInclusionRequests]);

  return (
    <div className="manager-request">
      <SidemenuRh />
      <div className="manager-request-container">
        <Header />
        <div className="manager-request-container__main">
          <div className="manager-request-container__main--painel">
            <div className="manager-request-content-title">
              <h1 className="manager-request-content-title__title">
                Todas as solicitações de inclusão
              </h1>
            </div>
            <div className="manager-request-content">
              <div className="manager-request-content__input-content">
                <label className="manager-request-content__label">
                  Consultar por nome
                </label>
                <div className="manager-request-content__input-group">
                  <input
                    className="manager-request-content__input"
                    type="text"
                    onInput={(e) => {
                      e.target.value = e.target.value.replace(/[0-9]/g, '');
                    }}
                    value={nameBeneficiaryFilter}
                    name="nomeBeneficiario"
                    placeholder="Pesquisar Beneficiário..."
                    onChange={(e) => handleAllFilterNameBeneficiary(e)}
                  />
                </div>
              </div>

              <div className="manager-request-content__checkbox-content">
                <CheckmarkStandard
                  value={status}
                  setValue={setStatus}
                  options={statusRequests}
                  label="Status de solicitação"
                  variant="hr"
                />
              </div>
              <div className="manager-request-content__button-content">
                <DownloadPersonalInformation
                  personalInformation={personalInformation}
                />
              </div>
            </div>
            <div>
              <TableHeader
                anchorElDelete={anchorElDelete}
                handleClose={handleClose}
                handleDisplayMessage={handleDisplayMessage}
                popupPositiveClickHandler={popupPositiveClickHandler}
              />
              {filteredBeneficiariesByName?.map((item, index) => (
                <TableResult
                  key={index}
                  data={item}
                  openDeleteModalHandler={openDeleteModalHandler}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManagerRequestInclusion;
