import { useEffect, useState } from 'react';
import { HiTrash } from 'react-icons/hi';
import { TbTrashOff } from 'react-icons/tb';
import { BsCheck2Circle } from 'react-icons/bs';
import { FaRegCircle } from 'react-icons/fa';
import Button from 'components/DS/Button';
import ReactDatePicker from 'react-datepicker';
import { ClickAwayListener } from '@material-ui/core';
import { BiEdit } from 'react-icons/bi';
import moment from 'moment';
import services from 'apps/hr/services';

const CardExclusionSchedule = ({
  user,
  item,
  index,
  setAnchorElDeletePopover,
  currentPage,
  getExclusionSchedule
}) => {
  const [editedDate, setEditedDate] = useState('');
  const [openEditDate, setOpenEditDate] = useState(false);
  const [minExclusaoDate, setMinExclusaoDate] = useState(undefined);
  const [maxExclusaoDate, setMaxExclusaoDate] = useState(undefined);
  const [openPopUp, setOpenPopUp] = useState(false);

  const updateScheduleDateHandler = async () => {
    const scheduleDate = moment(editedDate).format('DD-MM-YYYY');
    const response =await services.scheduleExclusion.updateScheduleDate(
      item.servico_agen_id,
      scheduleDate,
      user.mainEstipulante,
      user.id
    );

    if (response.status === 200) {
      getExclusionSchedule(currentPage);
      setOpenEditDate(false);
    }
  };

  useEffect(() => {
    if (openEditDate) {
      updateScheduleDateHandler();
    }
  }, [editedDate]);

  useEffect(() => {
    const today = new Date().setHours(0, 0, 0, 0);
    const tomorrow = today + 24 * 60 * 60 * 1000;
    const threeMonthsFromNow = new Date().setMonth(new Date().getMonth() + 3);

    setMinExclusaoDate(new Date(tomorrow));
    setMaxExclusaoDate(new Date(threeMonthsFromNow));
  }, []);

  return (
    <tr className="table-exclusion-schedule__tbody-tr" key={index}>
      <td>{item.beneficiario}</td>
      <td>{item.plano}</td>
      <td>{item.produto === 1 ? 'Saúde' : 'Dental'}</td>
      <td>{item.seguradora}</td>
      <td>{item.apolice}</td>
      {openEditDate ? (
        <td className="table-exclusion-schedule__tbody-input-date">
          <ClickAwayListener onClickAway={() => setOpenEditDate(false)}>
            <div>
              <ReactDatePicker
                className="table-exclusion-schedule__input-date"
                autoFocus="true"
                selected={new Date(item.data_agen)}
                popperPlacement="bottom-end"
                placeholderText="..."
                minDate={minExclusaoDate}
                maxDate={maxExclusaoDate}
                locale="ptBR"
                dateFormat="dd/MM/yyyy"
                onChange={(date) => setEditedDate(date)}
              />
            </div>
          </ClickAwayListener>
        </td>
      ) : (
        <td
          onMouseEnter={() => {
            if (item.mov_criada === 0) {
              if (item.usuario_rh_id !== user.id) {
                setOpenPopUp(true);
              } else {
                setOpenPopUp(false);
              }
            }
          }}
          onMouseLeave={() => setOpenPopUp(false)}
        >
          <div
            className="table-exclusion-schedule__tbody-input-date"
            onClick={() => setOpenEditDate(true)}
          >
            <span>{moment(item.data_agen).format('DD/MM/YYYY')}</span>
            {item.mov_criada === 0 ? (
              <Button
                size="small"
                variant={`${
                  item.usuario_rh_id === user.id
                    ? 'transparent'
                    : 'transparent-disabled'
                }`}
              >
                <BiEdit size={20} />
              </Button>
            ) : null}
          </div>
          {openPopUp ? (
            <div className="table-exclusion-schedule__tbody-pop-up">
              <p>Somente o usuário que criou pode alterar a data.</p>
            </div>
          ) : null}
        </td>
      )}
      <td className="table-exclusion-schedule__tbody-td-icon">
        {item.mov_criada === 1 ? (
          <BsCheck2Circle
            className="table-exclusion-schedule__icon--checked"
            size={20}
          />
        ) : (
          <FaRegCircle className="table-exclusion-schedule__icon" size={18} />
        )}
      </td>
      <td className="table-exclusion-schedule__tbody-td-icon">
        {item.mov_conc ? (
          <BsCheck2Circle
            className="table-exclusion-schedule__icon--checked"
            size={20}
          />
        ) : (
          <FaRegCircle className="table-exclusion-schedule__icon" size={18} />
        )}
      </td>
      <td>
        {item.mov_criada === 0 ? (
          <Button
            size="small"
            variant="remove"
            onClick={() => setAnchorElDeletePopover(item)}
          >
            <HiTrash size={20} />
          </Button>
        ) : (
          <Button size="small" variant="transparent-disabled">
            <TbTrashOff size={20} />
          </Button>
        )}
      </td>
    </tr>
  );
};

export default CardExclusionSchedule;
