import PopperDeleteConfirm from 'apps/broker/components/popperDeleteConfirmation';
import './style.scss';

const TableHead = ({
  anchorElDelete,
  handleClose,
  handleDisplayMessage,
  popupPositiveClickHandler
}) => {
  return (
    <div className="billing-header">
      <div>
        <h4>Ano</h4>
      </div>
      <div>
        <h4>Mês</h4>
      </div>
      <div>
        <h4>Fatura Mensal</h4>
      </div>
      <div className="billing-header__column--modal">
        <PopperDeleteConfirm
          anchorElDelete={anchorElDelete}
          handleClose={handleClose}
          handleDisplayMessage={handleDisplayMessage}
          popupPositiveClickHandler={popupPositiveClickHandler}
        />
      </div>
      <div></div>
    </div>
  );
}

export default TableHead;
