import { useEffect, useState } from 'react';
import { meses } from 'utils/arrays/meses';
import Button from 'components/DS/Button';
import TextEditor from '../TextEditor';
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { useForm } from 'react-hook-form';
import services from 'apps/broker/services';
import { toast } from 'react-hot-toast';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './style.scss';

const CreateNewBilling = ({
  setNewBilling,
  newBilling,
  selectedTask,
  contractInfo,
  setTransitionLeave,
  getAllBilling
}) => {
  const { register, handleSubmit, setValue, watch } = useForm();

  const [valorMensal, setValorMensal] = useState('');
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [files, setFiles] = useState([]);
  const [isDisabled, setIsDisabled] = useState(true);

  const mes = watch('mes') || '';
  const ano = watch('ano') || '';
  const valor = watch('valor_mensal') || '';

  const handleChange = (e) => {
    setValorMensal(e.target.value);
    const inputValue = valorMensal.replace('R$', '');
    setValue('valor_mensal', inputValue);
  };
  //
  const handleChangeMessage = () => {
    const htmlMessage = draftToHtml(
      convertToRaw(editorState.getCurrentContent())
    );
    const mensagemSemHtml = htmlMessage.replace(/<\/?[^>]+(>|$)/g, '');
    setValue('mensagem', mensagemSemHtml);
  };

  const handleFileChange = () => {
    setValue('files', files);
  };

  const onSubmit = async (data) => {
    setIsDisabled(true);

    const formData = new FormData();

    Array.from(data.files).forEach((file) => {
      formData.append('files', file);
    });

    for (let [key, value] of Object.entries(data)) {
      if (key.files) {
        return;
      }
      formData.append(key, value);
    }

    formData.append('contrato_id', contractInfo.contrato_id);
    formData.append('task_id', Number(selectedTask));

    const response = await services.billing.createNewBilling(formData);
    if (response.status === 201) {
      toast.dismiss();
      toast.success('Faturamento criado com sucesso.', {
        duration: 2500
      });
      setFiles([]);
      getAllBilling();
      setNewBilling(false);
      setTransitionLeave(true);
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente mais tarde.', {
        duration: 2500
      });
    }
  };

  useEffect(() => {
    handleChangeMessage();
  }, [editorState]);

  useEffect(() => {
    handleFileChange();
  }, [files]);

  useEffect(() => {
    if (!mes || !ano || !valor) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [mes, ano, valor]);

  return (
    <div
      className="modal-dark-bg-new-billing"
      style={{ animation: !newBilling ? 'fade-out 0.225s forwards' : '' }}
    >
      <div className="modal-new-billing">
        <form className="new-billing-content" onSubmit={handleSubmit(onSubmit)}>
          <div className="new-billing-content__title">
            <h3>Novo Faturamento</h3>
            <Button variant="remove" onClick={() => setNewBilling(false)}>
              X
            </Button>
          </div>
          <div className="new-billing-form">
            <div className="new-billing-form-content">
              <label className="new-billing-form-content__label">Ano</label>
              <input
                className="new-billing-form-content__input"
                type="text"
                {...register('ano')}
              />
            </div>
            <div className="new-billing-form-content">
              <label className="new-billing-form-content__label">Mês</label>
              <select
                className="new-billing-form-content__input"
                {...register('mes')}
              >
                <option value="">Selecione</option>
                {meses.map((mes) => (
                  <option value={mes}>{mes}</option>
                ))}
              </select>
            </div>
            <div className="new-billing-form-content">
              <label className="new-billing-form-content__label">
                Valor Mensal
              </label>
              <input
                className="new-billing-form-content__input"
                type="text"
                placeholder="R$ 0,00"
                value={valorMensal}
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="new-billing-document-content">
            <TextEditor
              editorState={editorState}
              setEditorState={setEditorState}
              files={files}
              setFiles={setFiles}
            />
          </div>
          <div className="new-billing-form-button">
            {isDisabled ? (
              <Button
                type="submit"
                variant="disabled"
                size="medium"
                isDisabled={true}
              >
                Criar Faturamento
              </Button>
            ) : (
              <Button
                type="submit"
                variant="save"
                size="medium"
                isDisabled={false}
              >
                Criar Faturamento
              </Button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CreateNewBilling;
