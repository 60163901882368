import { useState, useEffect } from 'react';
import {
  ButtonContainer,
  Content,
  DarkenBackground,
  PopperWrapper,
  TitleContainer
} from './styles';
import { ClickAwayListener } from '@material-ui/core';
import ReactDatePicker from 'react-datepicker';
import { RiSendPlaneFill } from 'react-icons/ri';

const AgendamentoPopper = ({
  setAgendarExclusao,
  dataExclusao,
  setDataExclusao,
  submitRemovalRequest,
  loading,
  contratosToRemove
}) => {
  const [minExclusaoDate, setMinExclusaoDate] = useState(undefined);
  const [maxExclusaoDate, setMaxExclusaoDate] = useState(undefined);

  const closeAgendamentoPopperHandler = () => {
    setAgendarExclusao(false);
  };

  const envioAgendamentoClickHandler = () => {
    submitRemovalRequest();
    setAgendarExclusao(false);
  };

  useEffect(() => {
    const today = new Date().setHours(0, 0, 0, 0);
    const tomorrow = today + 24 * 60 * 60 * 1000;
    const threeMonthsFromNow = new Date().setMonth(new Date().getMonth() + 3);

    setMinExclusaoDate(new Date(tomorrow));
    setMaxExclusaoDate(new Date(threeMonthsFromNow));
  }, []);

  return (
    <DarkenBackground>
      <ClickAwayListener onClickAway={closeAgendamentoPopperHandler}>
        <PopperWrapper>
          <TitleContainer>
            <span>Agendar pedido</span>
          </TitleContainer>
          <Content>
            <div className="agendamento-row">
              <div>
                <span className="agendamento-span">Data de agendamento:</span>
              </div>
              <div>
                <ReactDatePicker
                  className="input-data"
                  minDate={minExclusaoDate}
                  maxDate={maxExclusaoDate}
                  selected={dataExclusao}
                  value={dataExclusao}
                  locale="ptBR"
                  onChange={(date) => setDataExclusao(date)}
                  placeholderText="..."
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>
            <div className="disclaimer-container">
              <span>
                <strong>Atenção:</strong> Escolha a data na qual você deseja que a Innoa receba
                essa demanda para efetuar dentro da operadora.
              </span>
            </div>
            <div className="disclaimer-container">
              <span>Tempo limite de agendamento: <strong>3 meses</strong>.</span>
            </div>
            {!contratosToRemove?.length ? (
              <div className="disclaimer-container">
                <span>É necessário a escolha de pelo menos um plano.</span>
              </div>
            ) : (
              <div></div>
            )}
          </Content>
          <ButtonContainer>
            <button
              onClick={
                dataExclusao && !loading && contratosToRemove?.length
                  ? envioAgendamentoClickHandler
                  : ''
              }
              className={`${
                dataExclusao && contratosToRemove?.length ? '' : 'disabled'
              }`}
            >
              <span>Enviar</span>
              <RiSendPlaneFill className="send-icon" />
            </button>
          </ButtonContainer>
        </PopperWrapper>
      </ClickAwayListener>
    </DarkenBackground>
  );
};

export default AgendamentoPopper;
