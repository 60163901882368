import http from 'services/http';

const createTaskSchedule = async (data) => {
  const res = await http.post('/billing/schedule-task/', data);
  return res;
};

const createNewBilling = async (formData) => {
  const res = await http.post('/billing/', formData);
  return res;
};

const insertDocument = async (contrato_id, formData) => {
  const res = await http.post(`/billing/documents/${contrato_id}`, formData);
  return res;
};

const createContact = async (data) => {
  const res = await http.post('/billing/contact', data);
  return res;
};

const sendEmail = async (faturamento_id) => {
  const res = await http.post(`/billing/${faturamento_id}`);
  return res;
};

const updateBillingDataById = async (faturamento_id, data) => {
  const res = await http.put(`/billing/update-data/${faturamento_id}`, data);
  return res;
};

const getAllBilling = async (contrato_id) => {
  const res = await http.get(`/billing/${contrato_id}`);
  return res.data;
};

const getTasksList = async (contrato_id) => {
  const res = await http.get(`/billing/tasks/${contrato_id}`);
  return res.data;
};

const getContractInformation = async (contract_id) => {
  const res = await http.get(`/billing/contract/${contract_id}`);
  return res.data;
};

const getDocumentsList = async (faturamento_id) => {
  const res = await http.get(`/billing/documents/${faturamento_id}`);
  return res.data;
};

const getContacts = async (estipulante_id) => {
  const res = await http.get(`/billing/contact/${estipulante_id}`);
  return res.data;
};

const getHistoric = async (faturamento_id) => {
  const res = await http.get(`/billing/historic/${faturamento_id}`);
  return res.data;
};

const deleteContact = async (id) => {
  const res = await http.delete(`/billing/contact/${id}`);
  return res;
};

const deleteDocument = async (document_id) => {
  const res = await http.delete(`/billing/documents/${document_id}`);
  return res;
};

const deleteBillingById = async (faturamento_id) => {
  const res = await http.delete(`/billing/${faturamento_id}`);
  return res;
};

const modules = {
  createTaskSchedule,
  createNewBilling,
  insertDocument,
  createContact,
  sendEmail,
  updateBillingDataById,
  getAllBilling,
  getTasksList,
  getContractInformation,
  getDocumentsList,
  getContacts,
  getHistoric,
  deleteContact,
  deleteDocument,
  deleteBillingById
};

export default modules;
