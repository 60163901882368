import { useEffect, useState } from 'react';
import { GiHealthNormal } from 'react-icons/gi';
import { FaTooth } from 'react-icons/fa';
import { IoIosEye } from 'react-icons/io';
import colors from 'styles/colors';
import ModalLogs from './modalLogs';
import Button from 'components/DS/Button';
import CheckDoc from 'assets/imgs/verification.png';
import services from 'apps/hr/services';
import { BsCheck2Circle } from 'react-icons/bs';
import { useUser } from 'contexts/user';
import { HiTrash } from 'react-icons/hi';
import StepProgress from './stepProgress';
import { TbTrashOff } from 'react-icons/tb';
import './style.scss';

const TableResult = ({ key, data, openDeleteModalHandler }) => {
  const { user } = useUser();
  const [showLogs, setShowLogs] = useState(false);
  const [showStatus, setShowStatus] = useState('');

  const funilStatus = [
    'Formulário Enviado',
    'Aguardando Revisão do RH',
    'Aguardando correção do beneficiário',
    'Movimentação de Inclusão Enviada'
  ];

  const showPopUpStatus = (step) => {
    const label = funilStatus.find((status) => status === step);
    setShowStatus(label);
  };

  const formatHistoricArray = () => {
    const historicFiltered = data.status_historico.filter(
      (item) => item.nome !== 'Solicitação Criada'
    );
    const historicMapped = historicFiltered.map((item) => item.nome);
    const uniqueHistoric = [...new Set(historicMapped)];

    return uniqueHistoric;
  };

  const onReview = async () => {
    const response = await services.inclusionRequest.generateReviewLink({
      titularId: data.beneficiario_id,
      batchId: data.batch_id,
      rhUserId: user.id
    });

    const formUrl = `/formulario-inclusao-plano-saude?token=${response.token}&reset=true`;
    window.open(formUrl, '_blank');
  };

  useEffect(() => {
    data.status_historico.sort((a, b) => {
      const dateComparison = new Date(a.created_at) - new Date(b.created_at);

      if (dateComparison === 0) {
        return a.nome === 'Solicitação Criada' ? -1 : 1;
      }

      return dateComparison;
    });
  }, [data.status_historico, showLogs]);

  return (
    <div className="manager-request-table-result" key={key}>
      <div></div>
      <div>
        <span className="manager-request-table-result__span">
          {data.titular_nome}
        </span>
      </div>
      <div>
        {data.tipo_solicitacao === 'saude' && (
          <GiHealthNormal color={colors.genoaDarkBlue} />
        )}
        {data.tipo_solicitacao === 'odonto' && (
          <FaTooth color={colors.genoaDarkBlue} />
        )}
        {data.tipo_solicitacao === 'saude_odonto' && (
          <div className="manager-request-table-result__icons-container">
            <GiHealthNormal color={colors.genoaDarkBlue} />
            <FaTooth color={colors.genoaDarkBlue} />
          </div>
        )}
      </div>
      <div className="manager-request-table-result__funel-steps">
        <StepProgress
          step={formatHistoricArray().length}
          historic={data.status_atual}
          showPopUpStatus={showPopUpStatus}
          setShowStatus={setShowStatus}
        />
        <div>
          <Button variant="transparent" onClick={() => setShowLogs(true)}>
            <IoIosEye size={20} />
          </Button>
        </div>
        {showStatus ? (
          <div className="manager-request-table-result__label-container">
            <p className="manager-request-table-result__label">{showStatus}</p>
          </div>
        ) : null}
        {showLogs ? (
          <ModalLogs setShowLogs={setShowLogs} logs={data.status_historico} />
        ) : null}
      </div>
      <div>
        {/* acessa o formulário externo já preenchido*/}
        {data.status_atual === 'Movimentação de Inclusão Enviada' ? (
          <BsCheck2Circle className="manager-request-table-result__icon" />
        ) : (
          <Button variant="transparent" onClick={onReview}>
            <img
              className="manager-request-table-result__img"
              src={CheckDoc}
              alt="ícone de documento"
            />
          </Button>
        )}
      </div>
      <div>
        {data.status_atual !== 'Movimentação de Inclusão Enviada' ? (
          <Button
            size="small"
            variant="remove"
            onClick={() => openDeleteModalHandler(data.beneficiario_id)}
          >
            <HiTrash size={20} />
          </Button>
        ) : (
          <Button size="small" variant="transparent-disabled">
            <TbTrashOff size={20} />
          </Button>
        )}
      </div>
    </div>
  );
};

export default TableResult;
