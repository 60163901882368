import { useEffect, useState } from 'react';
import TableHead from './TableHead';
import services from 'apps/broker/services';
import TableBody from './TableBody';
import './styles.scss';

const TableResult = ({ inputNameFilter, healthProductFilter, dentalProductFilter, activeProducts }) => {
  const [data, setData] = useState([]);
  const [estipulantes, setEstipulantes] = useState([]);
  const [dataFilter, setDataFilter] = useState([]);
  const [estipulante, setEstipulante] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [estipulantesOrderSelected, setEstipulantesOrderSelected] = useState(1);
  const [vidasOrderSelected, setVidasOrderSelected] = useState(1);
  const [ticketsOrderSelected, setTicketsOrderSelected] = useState(1);
  const [clientePeriodoOrderSelected, setClientePeriodoOrderSelected] =
    useState(1);

  const getData = () => {
    services.policyOwner
      .getAll()
      .then((resp) => {
        setData(resp.data);
        setEstipulantes(resp.data);
        setDataFilter(resp.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <section className="content-list">
      <TableHead
        data={data}
        editMode={editMode}
        setEditMode={setEditMode}
        selectedCheckboxes={selectedCheckboxes}
        vidasOrderSelected={vidasOrderSelected}
        estipulantesOrderSelected={estipulantesOrderSelected}
        setVidasOrderSelected={setVidasOrderSelected}
        setTicketsOrderSelected={setTicketsOrderSelected}
        setClientePeriodoOrderSelected={setClientePeriodoOrderSelected}
        setEstipulantesOrderSelected={setEstipulantesOrderSelected}
        setSelectedCheckboxes={setSelectedCheckboxes}
        clientesOrderSelected={estipulantesOrderSelected}
        ticketsOrderSelected={ticketsOrderSelected}
        dataFilter={dataFilter}
        setDataFilter={setDataFilter}
        setData={setData}
        getData={getData}
      />

      <TableBody
        editMode={editMode}
        estipulantes={estipulantes}
        getData={getData}
        inputNameFilter={inputNameFilter}
        healthProductFilter={healthProductFilter}
        dentalProductFilter={dentalProductFilter}
        activeProducts={activeProducts}
        estipulantesOrderSelected={estipulantesOrderSelected}
        vidasOrderSelected={vidasOrderSelected}
        ticketsOrderSelected={ticketsOrderSelected}
        clientePeriodoOrderSelected={clientePeriodoOrderSelected}
        selectedCheckboxes={selectedCheckboxes}
        setSelectedCheckboxes={setSelectedCheckboxes}
        estipulante={estipulante}
        setEstipulante={setEstipulante}
      />
    </section>
  );
};

export default TableResult;
