import tickets from './tickets';
import user from './user';
import inclusionRequest from './inclusionRequest';
import beneficiaries from './beneficiaries';
import contracts from './contracts';
import settings from './settings';
import scheduleExclusion from './scheduleExclusion';

const modules = {
  tickets,
  user,
  inclusionRequest,
  beneficiaries,
  contracts,
  settings,
  scheduleExclusion
};

export default modules;
