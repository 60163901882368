import React from "react";
import InputStandard from "components/Input/InputStandard";
import SelectStandard from "apps/broker/components/Select";
import estadosCivis from "utils/arrays/estadosCivis";
import nacionalidades from "utils/arrays/nacionalidades";
import maskCpf from "utils/inputMasks/maskCpf";
import maskData from "utils/inputMasks/maskData";
import { AbaDadosBasicosWrapper } from "./styles-abaDadosBasicos";


const AbaDadosBasicos = (
  { values,
    setInputValue,
    setSelectValue,
    camposInclusao
  }) => {

  const optionsSexo = [
    { option: "Masculino", value: "Masculino" },
    { option: "Feminino", value: "Feminino" },
  ];

  const optionsNacionalidade = nacionalidades.map((item) => ({
    option: item.nome, value: item.nome
  }));

  const optionsVinculo = [
    {option: "Cônjuge", value: "Cônjuge"},
    {option: "Filho(a)", value: "Filho(a)"},
    {option: "Mãe", value: "Mãe"},
    {option: "Pai", value: "Pai"},
    {option: "Sogro(a)", value: "Sogro(a)"},
    {option: "Tutelado(a)", value: "Tutelado(a)"},
    {option: "Outros", value: "Outros"},
  ]

  return (
    <AbaDadosBasicosWrapper>
      <div className="aba-container">
        <div className="aba-title-container">
          <span>1. Dados básicos</span>
        </div>
        {
          values && values['tipoBeneficiario'] === "Titular" ? (
            <div className="aba-input-container">
              <div className="aba-input-first-row">
                {
                  camposInclusao['nome'] ? (
                    <InputStandard
                      value={values['nome']}
                      setValue={setInputValue}
                      label={"Nome"}
                      name="nome"
                    />
                  ) : ""
                }
                {
                  camposInclusao['data_nascimento'] ? (
                    <InputStandard
                      value={values['data_nascimento']}
                      setValue={setInputValue}
                      label={"Data de nascimento"}
                      name="data_nascimento"
                      maskFunction={maskData}
                      maxLength={8}
                    />
                  ) : ""
                }
                {
                  camposInclusao['sexo'] ? (
                    <SelectStandard
                      value={values['sexo']}
                      setValue={setSelectValue}
                      label={"Sexo"}
                      name="sexo"
                      options={optionsSexo}
                    />
                  ) : ""
                }
              </div>

              <div className="aba-input-row-nacionalidade">
                {
                  camposInclusao['cpf'] ? (
                    <InputStandard
                      label="CPF"
                      value={values['cpf']}
                      setValue={setInputValue}
                      name="cpf"
                      maskFunction={maskCpf}
                      maxLength={11}
                    />
                  ) : ""
                }
                {
                  camposInclusao['estado_civil'] ? (
                    <SelectStandard
                      value={values['estado_civil']}
                      setValue={setSelectValue}
                      label={"Estado civil"}
                      name="estado_civil"
                      options={estadosCivis}
                    />
                  ) : ""
                }
                {
                  camposInclusao['nacionalidade'] ? (
                    <SelectStandard
                      value={values['nacionalidade']}
                      setValue={setSelectValue}
                      label={"Nacionalidade"}
                      name="nacionalidade"
                      options={optionsNacionalidade}
                    />
                  ) : ""
                }
              </div>
              <div className="aba-input-row-rg">
                {
                  camposInclusao['rg'] ? (
                    <InputStandard
                      value={values['rg']}
                      setValue={setInputValue}
                      label={"RG"}
                      name="rg"
                    />
                  ) : ""
                }
                {
                  camposInclusao['orgao_emissor_rg'] ? (
                    <InputStandard
                      value={values['orgao_emissor_rg']}
                      setValue={setInputValue}
                      label={"Orgão emissor RG"}
                      name="orgao_emissor_rg"
                    />
                  ) : ""
                }
                {
                  camposInclusao['data_expedicao_rg'] ? (
                    <InputStandard
                      value={values['data_expedicao_rg']}
                      setValue={setInputValue}
                      label={"Data de expedição de RG"}
                      name="data_expedicao_rg"
                      maskFunction={maskData}
                      maxLength={8}
                    />
                  ) : ""
                }
              </div>
              <div className="aba-input-row">
                {
                  camposInclusao['nome_mae'] ? (
                    <InputStandard
                      value={values['nome_mae']}
                      setValue={setInputValue}
                      label={"Nome da mãe"}
                      name="nome_mae"
                    />
                  ) : ""
                }
                {
                  camposInclusao['pis_pasep'] ? (
                    <InputStandard
                      value={values['pis_pasep']}
                      setValue={setInputValue}
                      label={"PIS / PASEP"}
                      name="pis_pasep"
                    />
                  ) : ""
                }
              </div>
            </div>
          ) : (<div className="aba-input-container">
          <div className="aba-input-first-row">
            {
              camposInclusao['nome'] ? (
                <InputStandard
                  value={values['nome']}
                  setValue={setInputValue}
                  label={"Nome"}
                  name="nome"
                />
              ) : ""
            }
            {
              camposInclusao['data_nascimento'] ? (
                <InputStandard
                  value={values['data_nascimento']}
                  setValue={setInputValue}
                  label={"Data de nascimento"}
                  name="data_nascimento"
                  maskFunction={maskData}
                  maxLength={8}
                />
              ) : ""
            }
            {
              camposInclusao['sexo'] ? (
                <SelectStandard
                  value={values['sexo']}
                  setValue={setSelectValue}
                  label={"Sexo"}
                  name="sexo"
                  options={optionsSexo}
                />
              ) : ""
            }
          </div>

          <div className="aba-input-row-nacionalidade">
            {
              camposInclusao['cpf'] ? (
                <InputStandard
                  label="CPF"
                  value={values['cpf']}
                  setValue={setInputValue}
                  name="cpf"
                  maskFunction={maskCpf}
                  maxLength={11}
                />
              ) : ""
            }
            {
              camposInclusao['estado_civil'] ? (
                <SelectStandard
                  value={values['estado_civil']}
                  setValue={setSelectValue}
                  label={"Estado civil"}
                  name="estado_civil"
                  options={estadosCivis}
                />
              ) : ""
            }
            {
              camposInclusao['nacionalidade'] ? (
                <SelectStandard
                  value={values['nacionalidade']}
                  setValue={setSelectValue}
                  label={"Nacionalidade"}
                  name="nacionalidade"
                  options={optionsNacionalidade}
                />
              ) : ""
            }
          </div>
          <div className="aba-input-row-vinculo">
            <SelectStandard
              value={values['vinculo']}
              setValue={setSelectValue}
              label={"Vínculo"}
              name="vinculo"
              options={optionsVinculo}
            />
            <InputStandard
              value={values['dataVinculo']}
              setValue={setInputValue}
              label={"Data de Vínculo"}
              name="dataVinculo"
              maskFunction={maskData}
              maxLength={8}
            />
          </div>
          
          <div className="aba-input-row">
            {
              camposInclusao['nome_mae'] ? (
                <InputStandard
                  value={values['nome_mae']}
                  setValue={setInputValue}
                  label={"Nome da mãe"}
                  name="nome_mae"
                />
              ) : ""
            }
            
          </div>
        </div>)
        }
      </div>
    </AbaDadosBasicosWrapper>
  )
}

export default AbaDadosBasicos;
