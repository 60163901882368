import styled from 'styled-components';
import colors from 'styles/colors';

export const WrapperRemoveBeneficiario = styled.section`
  min-width: 700px;
  max-width: 900px;
  box-sizing: content-box;

  header {
    background-color: ${colors.genoaBlue};
    padding: 1rem;

    .headerAdd {
      .titleHeader {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5rem;

        color: #fff;
        font-size: 1.125rem;

        > svg {
          font-size: 1.75rem;
        }
      }
    }
  }

  main {
    width: 90%;
    padding: 0.5rem;
    margin: 0 auto;

    .select-company {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 0.25rem;

      select {
        max-width: 70%;
        border: 1px solid ${colors.genoaBlue};
        border-radius: 0.5rem;
        padding: 0.25rem;

        &:hover,
        &:focus {
          outline: none !important;
        }
      }

      & + .select-company {
        margin-top: 1rem;
      }
    }
  }

  .choose-insurance {
    .container-select-insurance {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.75rem;

      padding: 2rem;

      .box-select-insurance {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        span {
          color: #c2c2c2;
          display: flex;
          align-items: center;
          gap: 0.5rem;
          cursor: pointer;

          transition: all 0.4s;

          span {
            color: #3b3838;
          }

          svg {
            width: 1.5rem;
            height: 1.5rem;
            fill: currentColor;
          }
        }

        span.select-custom {
          color: ${colors.genoaBlue};
        }

        > select {
          max-width: 60%;
          padding: 0.25rem 0.5rem;
          border: 1px solid ${colors.genoaBlue};
          border-radius: 1rem;

          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .container-select-typeUser {
    > div {
      width: 100%;
      > div {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
`;

export const RemoveBeneficiarioContainer = styled.div`
  min-height: 101vh;

  .beneficiario-info-container {
    padding-top: 10px;
    display: flex;
    flex-direction: column;
  }

  .beneficiario-info-title {
    font-family: 'Avenir Next GEO W05 Demi';
    margin-bottom: 5px;
  }

  .dependente-beneficiario-container-open {
    height: 100px;
    overflow: hidden;
    display: flex;
    justify-content: start;
    transition: height 0.4s ease-in-out;
    font-family: 'Avenir Next GEO W05 Demi';
    color: ${colors.genoaGrey};
    .dependentes-titular-first-row {
      display: flex;
      align-items: center;
      justify-content: start;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      .arrow-container {
        margin-left: 5px;
        margin-bottom: -2px;
      }

      .arrow-open-icon {
        transition: all 0.3s;
      }

      .arrow-close-icon {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }
      :hover {
        cursor: pointer;
      }
    }

    .dependentes-titular-second-row {
      padding-top: 10px;
    }
  }

  .dependentes-quantidade-info {
    margin-left: 7px;
  }

  .dependente-beneficiario-container {
    height: 40px;
    overflow: hidden;
    display: flex;
    display: flex;
    justify-content: start;
    transition: height 0.4s ease-in-out;
    font-family: 'Avenir Next GEO W05 Demi';
    color: ${colors.genoaGrey};
    .dependentes-titular-first-row {
      display: flex;
      align-items: center;
      justify-content: start;
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;

      .arrow-container {
        margin-left: 5px;
        margin-bottom: -2px;
      }

      .arrow-open-icon {
        transition: all 0.3s;
      }

      .arrow-close-icon {
        transform: rotate(-90deg);
        transition: all 0.3s;
      }
      :hover {
        cursor: pointer;
      }
    }

    .dependentes-titular-second-row {
      padding-top: 10px;
    }
  }

  .dependentes-titular-lista {
    color: ${colors.genoaGrey};
    font-family: 'Avenir Next GEO W05 Regular';
    padding-left: 15px;

    .dependente-nome {
      user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
    }
  }

  .remove-beneficiario-container {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    align-items: center;
  }

  .remove-info-title {
    font-family: 'Avenir Next GEO W05 Demi';
    font-size: 1.3rem;
  }

  .remove-planos-options {
    padding-top: 20px;
    select {
      font-family: "Avenir Next GEO W05 Regular";
      padding: 8px;
      width: 320px;
      border-radius: 8px;
      border: 1px ${colors.genoaBlue} solid;
      margin-top: 12px;

      :focus-visible {
        outline: 1px ${colors.genoaBlue}f5 auto;
      }
    }
  }

  .remove-plano-option {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
    }
    :hover {
      cursor: pointer;
    }
  }

  .remove-plano-option-selected {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    color: ${colors.genoaBlue};
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
      fill: currentColor;
    }
    :hover {
      cursor: pointer;
    }
  }

  .remove-plano-option-inactive {
    padding-top: 10px;
    padding-bottom: 10px;
    display: flex;
    color: ${colors.genoaDisabledGrey};
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    .plano-icon {
      width: 20px;
      height: 20px;
      padding-right: 5px;
      fill: currentColor;
    }

    .alert-icon-schedule {
      color: ${colors.genoaOrange};
    }
  }

  .request-exclusion-schedule {
    position: absolute;
    box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
    z-index: 1000;
    background-color: white;
    padding: 0px 10px 0px 10px;
    width: 300px;
    transform: translateX(-24px) translateY(8px);
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;

    .top-stripe {
      position: absolute;
      background-color: ${colors.genoaOrange};
      width: 100%;
      transform: translateX(-10px) translateY(0px);
      border-top-right-radius: 6px;
      border-top-left-radius: 6px;
      height: 6px;
    }
  }

  .request-exclusion-schedule-display {
    display: flex;
    flex-direction: column;
    padding-top: 8px;
    padding-bottom: 5px;
    row-gap: 7px;

    span {
      font-family: 'Avenir Next GEO W05 Regular';
      color: ${colors.genoaGrey};
    }

    .disclaimer {
      font-size: 14px;
      line-height: 16px;
    }
  }

  .request-exclusion-schedule-display::after {
    content: " ";
    position: absolute;
    bottom: 100%;  /* At the top of the tooltip */
    left: 36px;
    margin-left: -8px;
    border-width: 7px;
    border-style: solid;
    border-color: transparent ${colors.genoaOrange};
  }

  .remove-beneficiario-button-container {
    display: flex;
    justify-content: center;
    padding-top: 30px;
    .send-btn {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      background-color: #1e88f2;
      :hover {
        background-color: #1976d2;
      }
    }

    .send-btn-disabled {
      color: white;
      margin-top: 12px;
      padding: 4px 10px;
      }
    }
  }

  .react-datepicker__input-container {
    position: relative;
    display: inline-block;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
  }

  .input-data {
    width: 120px;
    border: 1px solid ${colors.genoaBlue};
    display: inline-block;
    overflow: hidden;
    text-align: center;
    font-family: 'Avenir Next GEO W05 Regular';
    border-radius: 7px;
    line-height: 1.2rem;
    padding: 6px;
  }

  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
    width: min-content;
  }


  .span-date-container {
    padding-top: 20px;
    width: 120px;
    height: 33px;
    display: flex;
    justify-content: space-between;
    transition: all .4s cubic-bezier(0.4, 0, 0.2, 1);
  }

  .span-date-title {
    padding-top: 10px;
  }

  .datepicker-selector-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
  }

  .clear-date-option {
    font-size: 0.8rem;
    color: ${colors.genoaBlue};
    height: 22.8px;
    font-weight: bold;
    padding-top: 5px;
    :hover {
      cursor: pointer;
      border-bottom: 1px ${colors.genoaBlue} solid;
    }
  }

  .clear-date-option-container {
    min-height: 23px;
  }
`;
