import { useState, useEffect } from 'react';
import { Radio } from '@mui/material';
import ReactDatePicker from 'react-datepicker';
import SelectStandard from 'apps/broker/components/Select';
import { toast } from 'react-hot-toast';
import services from 'apps/broker/services';
import './style.scss';
import Button from 'components/DS/Button';

const DrawerNovaTask = ({
  openDrawer,
  setOpenDrawer,
  corretores,
  estipulantes,
  leads,
  setSelectEstipulante,
  selectEstipulante,
  setSelectLead,
  selectLead,
  estipulanteContratos,
  getTasksData
}) => {
  const [responsavel, setResponsavel] = useState(undefined);
  const [tituloTask, setTituloTask] = useState('');
  const [descricao, setDescricao] = useState('');
  const [produto, setProduto] = useState('saude');
  const [tipoCliente, setTipoCliente] = useState('estipulante');
  const [contrato, setContrato] = useState(undefined);
  const [dataTask, setDataTask] = useState(new Date());
  const [isDisabled, setDisabled] = useState(true);

  const corretoresOptions = corretores?.map((item) => {
    return { value: item.id, option: item.nome };
  });

  const setTituloTaskChangeHandler = (e) => {
    setTituloTask(e.target.value);
  };

  const setDescricaoChangeHandler = (e) => {
    setDescricao(e.target.value);
  };

  const radioTipoProdutoClickHandler = (e) => {
    setProduto(e.target.value);
  };

  const getProdutoId = () => {
    switch (produto) {
      case 'dental':
        return 2;
      case 'vida':
        return 3;
      default:
        return 1;
    }
  };

  const radioTipoClienteClickHandler = (e) => {
    setTipoCliente(e.target.value);
  };

  const createNewTaskHandler = async () => {
    const produto_id = getProdutoId();
    let body = {};
    if (selectEstipulante && produto) {
      body = {
        usuario_id: responsavel,
        titulo_task: tituloTask,
        descricao,
        estipulante_id: selectEstipulante,
        lead_potencial_id: null,
        produto_id,
        contrato_id: contrato ? contrato : null,
        data_task: dataTask
      };
    } else if (selectLead && produto) {
      body = {
        usuario_id: responsavel,
        titulo_task: tituloTask,
        descricao,
        estipulante_id: null,
        lead_potencial_id: selectLead,
        produto_id,
        contrato_id: null,
        data_task: dataTask
      };
    } else if (!selectEstipulante && !selectLead) {
      body = {
        usuario_id: responsavel,
        titulo_task: tituloTask,
        descricao,
        estipulante_id: null,
        lead_potencial_id: null,
        produto_id: null,
        contrato_id: null,
        data_task: dataTask
      };
    }

    const response = await services.tasks.createNewTask(body);
    if (response.status === 201) {
      setResponsavel(false);
      setTituloTask('');
      setDescricao('');
      setProduto('saude');
      setContrato(undefined);
      setDataTask(new Date());
      setSelectEstipulante(0);
      setSelectLead(0);
      toast.dismiss();
      toast.success('Nova task criada com sucesso!', {
        duration: 2500
      });
      setOpenDrawer(false);
      getTasksData();
    } else {
      toast.dismiss();
      toast.error('Algo deu errado, tente novamente!', {
        duration: 2500
      });
    }
  };

  useEffect(() => {
    if (responsavel && tituloTask !== '' && dataTask) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [responsavel, tituloTask, dataTask]);

  return (
    <div
      className="modal-dark-bg-new-task"
      style={{ animation: !openDrawer ? 'fade-out 0.225s forwards' : '' }}
    >
      <div className="new-task">
        <div className="new-task-content">
          <div className="new-task-content-header">
            <span className="new-task-content-header__title">Nova task</span>
          </div>
          <div className="new-task-content-form-container">
            <div className="new-task-content-first-form-option">
              <div>
                <div>
                  <SelectStandard
                    name="responsavel"
                    label="Responsável"
                    options={[
                      { value: undefined, option: 'Selecione' },
                      ...corretoresOptions
                    ]}
                    value={responsavel}
                    setValue={(object) => setResponsavel(object.value)}
                  />
                </div>
              </div>
              <div>
                <div>
                  <label className="new-task-content-first-form-option__label">
                    Título da task:
                  </label>
                </div>
                <div>
                  <input
                    className="new-task-content-first-form-option__input"
                    type="text"
                    onChange={setTituloTaskChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="new-task-content-second-form-option">
              <div className="new-task-content-second-form-option__textarea-container">
                <div>
                  <label className="new-task-content-second-form-option__label">
                    Descrição da task
                  </label>
                </div>
                <div>
                  <textarea
                    className="new-task-content-second-form-option__text-area"
                    rows={4}
                    maxLength={255}
                    onChange={setDescricaoChangeHandler}
                  />
                </div>
              </div>
            </div>
            <div className="new-task-content-third-form-option">
              <div className="new-task-content-third-form-option__clint-type-container">
                <div className="new-task-content-third-form-option__radio-container">
                  <Radio
                    size="small"
                    value="estipulante"
                    onClick={radioTipoClienteClickHandler}
                    name="cliente"
                    className="radio-input dont-close"
                    checked={tipoCliente === 'estipulante'}
                  />
                  <label className="new-task-content-third-form-option__label">
                    Estipulante
                  </label>
                </div>
                <div className="new-task-content-third-form-option__radio-container">
                  <Radio
                    size="small"
                    value="lead"
                    onClick={radioTipoClienteClickHandler}
                    name="cliente"
                    className="radio-input dont-close"
                    checked={tipoCliente === 'lead'}
                  />
                  <label className="new-task-content-third-form-option__label">
                    Lead
                  </label>
                </div>
                {tipoCliente === 'estipulante' ? (
                  <div className="new-task-content-third-form-option__select">
                    <SelectStandard
                      dropDownHeight={350}
                      name="estipulantes"
                      label=""
                      options={[
                        { value: 0, option: 'Selecione' },
                        ...estipulantes
                      ]}
                      value={selectEstipulante}
                      setValue={(object) => setSelectEstipulante(object.value)}
                    />
                  </div>
                ) : (
                  <div className="new-task-content-third-form-option__select">
                    <SelectStandard
                      name="leads"
                      label=""
                      options={[{ value: 0, option: 'Selecione' }, ...leads]}
                      value={selectLead}
                      setValue={(object) => setSelectLead(object.value)}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="new-task-content-fourth-form-option">
              <div className="new-task-content-fourth-form-option__product-type-container">
                <div className="new-task-content-fourth-form-option__radio-container">
                  <Radio
                    value="saude"
                    onClick={radioTipoProdutoClickHandler}
                    name="produto"
                    className="radio-input dont-close"
                    checked={produto === 'saude'}
                  />
                  <label className="new-task-content-fourth-form-option__label">
                    Saúde
                  </label>
                </div>
                <div className="new-task-content-fourth-form-option__radio-container">
                  <Radio
                    value="dental"
                    onClick={radioTipoProdutoClickHandler}
                    name="produto"
                    className="radio-input dont-close"
                    checked={produto === 'dental'}
                  />
                  <label className="new-task-content-fourth-form-option__label">
                    Dental
                  </label>
                </div>
                <div className="new-task-content-fourth-form-option__radio-container">
                  <Radio
                    value="vida"
                    onClick={radioTipoProdutoClickHandler}
                    name="produto"
                    className="radio-input dont-close"
                    checked={produto === 'vida'}
                  />
                  <label className="new-task-content-fourth-form-option__label">
                    Vida
                  </label>
                </div>
                {tipoCliente === 'estipulante' ? (
                  <div className="new-task-content-fourth-form-option__select">
                    <SelectStandard
                      name="contrato"
                      label=""
                      options={[
                        { value: undefined, option: 'Selecione' },
                        ...estipulanteContratos
                      ]}
                      value={contrato}
                      setValue={(object) => setContrato(object.value)}
                    />
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>

            <div className="new-task-content-fifth-form-option">
              <div>
                <label className="new-task-content-fifth-form-option__label">
                  Data da task
                </label>
              </div>
              <div>
                <ReactDatePicker
                  className="new-task-content-fifth-form-option__input-date"
                  selected={dataTask}
                  locale="ptBR"
                  minDate={new Date()}
                  onChange={(data) => setDataTask(data)}
                  dateFormat="dd/MM/yyyy"
                />
              </div>
            </div>

            <div className="new-task-content-form-buttons">
              <Button
                variant="cancel"
                size="small"
                onClick={() => setOpenDrawer(false)}
              >
                Cancelar
              </Button>
              <Button
                variant={isDisabled ? 'disabled' : 'save'}
                size="small"
                onClick={isDisabled ? () => {} : createNewTaskHandler}
              >
                Criar task
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerNovaTask;
